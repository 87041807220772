(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleCloseList();
    handleOpenList();
    handleShowTemplate();
  }

  var handleApplyTemplate = function(contentSelected) {
    $("#apply-message-template").on("click", function() {
      $("textarea.enter-messsage-content").val(contentSelected);
      $('textarea.enter-messsage-content').css("height", 'auto');
      const height = $('textarea.enter-messsage-content')[0].scrollHeight
      $('textarea.enter-messsage-content').css("height", `${height}px`);
      $('#chat-send').show()

      $("#show-message-template-modal").modal("hide")
      $("#message-template-list").removeClass("show");
    })
  }

  var handleCloseList = function() {
    $("#message-template-list .back-btn").on("click", function(e) {
      $("#message-template-list").removeClass("show")
    })
  }

  var handleOpenList = function() {
    $("#user-chat-show").delegate("#show-message-template", "click", function(e) {
      $("#message-template-list").addClass("show")
    })
  }

  var handleShowTemplate = function() {
    $("#message-template-list .show-message-template").on("click", function() {
      $("#show-message-template-modal").modal("show")
      const id = $(this).attr("message-template-id");
      loadData(id);
    })
  }

  async function loadData(id) {
    $(".preload-decrypt").show();
    let result = {};
    let loading = true
    let contentSelected = "";

    await $.ajax({
      url: `/api/v1/message_templates/${id}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        contentSelected = result.content;
        loading = false
      }
    });

    if (!loading) {
      $(".preload-decrypt").hide();
      $("#show-message-template-modal .modal-title").text(result.title)
      const content = result.content.replace(/\r?\n|\r/g, '<br>')
      $("#show-message-template-modal .modal-body").text(content)
    }
    handleApplyTemplate(contentSelected)
  }
}).call(this);
