(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleReply();
    handleClickReplyMessage();
  }

  var handleClickReplyMessage = function() {
    $("#my-chat-history").delegate(".reply-section-content", "click", function(element) {
      const repId = $(this).attr("reply-id");
      const repElement = `#message-${repId}`

      if ($(repElement).length > 0) {
        $(repElement).css({ backgroundColor: '#4A154B52' });
        document.querySelector(repElement).scrollIntoView({
          behavior: 'smooth'
        });
        setTimeout(function() {
          $(repElement).css({ backgroundColor: 'transparent' });
        }, 1500);
      } else {
        const roomId = $("#message-content input[name=room_id]").val();
        var roomData;
        const lastMessageId = $('#my-chat-history').children().eq(1).attr("id").substring(8)
        const current_user_id = parseInt(sessionStorage.getItem('current_user_id'));
        let pollArray = []

        loadMore(roomId, lastMessageId, repId, roomData, current_user_id, pollArray)
      }
    })
  }

  async function loadMore(roomId, lastMessageId, repId, roomData, current_user_id, pollArray) {
    let isLoading = true;
    $(".preload-decrypt").show();
    await $.ajax({
      url: `/api/v1/conversations/listing?room_id=${roomId}&last_id=${lastMessageId}&reply_id=${repId}`,
      type: "GET",
      async: true,
      success: function(data) {
        roomData =  data.data;
        isLoading = false;
      }
    });

    if (!isLoading) {
      const ulContent = listingConversation(roomData, current_user_id, pollArray);
      $("#my-chat-history").prepend(ulContent);
      const elementList = roomData.conversations.map((item) => `#message-${item.id}`).join(", ");
      handleSelectMessage($(elementList));
      handleLongPress($(elementList));
      if ($(".conversation-messages .round").hasClass("open")) {
        $(".conversation-messages .round").addClass("open");
      } else {
        $(".conversation-messages .round").removeClass("open");
      }
      handleVoice(roomData);
      pollArray.forEach(function(message) {
        $(`.simple-timer-${message.id}`).syotimer({
          date: new Date(message.expirationTime),
        });
      })

      if ($(`#message-${repId}`).length) {
        $(`#message-${repId}`).css({ backgroundColor: '#4A154B52' });
        document.querySelector(`#message-${repId}`).scrollIntoView({
          behavior: 'smooth'
        });
        setTimeout(function() {
          $(`#message-${repId}`).css({ backgroundColor: 'transparent' });
        }, 1500);
      } else {
        $(".toast-body").text("This message has been deleted");
        $(".toast-block").show();
        $('.toast').toast({ delay: 3000})
        $('.toast').toast("show")
        $(".toast-block").animate({width:'show'}, 3000);
      }


      $(".preload-decrypt").hide();
    }
  }

  var handleReply = function() {
    $('.checkbox-reply').on('click', function() {
      if (delete_message_id.length == 1) {
        var message = $(`#message-${ delete_message_id[0] }`);
        var author_name = message.find('.conversation-name').text() || "You";
        let content = ""
        switch (message.attr("message-type")) {
          case "voice":
            content = "Voice message";
            break;
          case "image":
            content = "Gallery";
            break;
          case "file":
            content = "File";
            break;
          case "csv":
            content = "File";
            break;
          case "poll":
            content = "Poll";
            break;
          default:
            content = message.find('.message-content').text();
        }
        $('.reply-section .reply-author-name').text(author_name);
        $('.reply-section .reply-content').text(content);
        $('#reply_id').val(delete_message_id[0]);
        $('.chat-conversation').addClass('reply-section-open');
        $('.reply-section').show();
        $('.forward-section').remove();
        holdClick();
        rollToBottom();
      }
    })

    $('.close-reply-section').on('click', function() {
      $('.chat-conversation').removeClass('reply-section-open');
      $('.reply-section').hide();
      $('.reply-section p').html('');
      $('#reply_id').val('');
    })
  }
}).call(this);
