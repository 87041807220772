(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var gotoConversation = true;
  var groupSelectedIds = [];
  var activedTab;

  var init = function() {
    handleEnableSelectGroup();
    handleCloseSelectGroup();
    handleNavBar();
    handleClear();
    handleDelete();
    handleSingleDelete();
    handleSingleClear();
    handleLongPressGroup();
    handleComplexDeleteGroup();

    $("#pills-chat, #pills-groups, #pills-zero-groups").delegate("ul.room-list li", "click", function(e) {
      if (gotoConversation) {
        handleClickConversation(this);
      } else {
        handleSelectGroup(this)
      }
    })
  }

  resetHandleOnGroup = function() {
    handleEnableSelectGroup(); //select on right tab
    handleCloseSelectGroup(); //close select
    handleComplexDeleteGroup(); //delete, clear multiple group
    handleSingleDelete(); //single delete
    handleSingleClear(); //sing clear
  }

  var handleComplexDeleteGroup = function() {
    $(".side-menu").delegate("a[data-bs-target='#delete-complex-group-modal']", "click", function() {
      const currentUserId = parseInt(sessionStorage.getItem('current_user_id'));
      let groupAdmin = [];
      let groupMember = [];

      groupSelectedIds.forEach(function(roomId) {
        let roomData = roomList.find(element => element.id === roomId);
        if (roomData.adminId == currentUserId) {
          groupAdmin.push(roomData.roomName)
        } else {
          groupMember.push(roomData.roomName)
        }
      });
      $("#delete-complex-group-modal .group-admin-name").text(groupAdmin.join(", "));
      $("#delete-complex-group-modal .group-member-name").text(groupMember.join(", "));

      if (groupAdmin.length === 0) {
        $("#delete-complex-group-modal .group-admin-block").hide();
        $("#delete-complex-group-modal .modal-title").text("Leave Group");
      } else {
        $("#delete-complex-group-modal .group-admin-block").show();
      }

      if (groupMember.length === 0) {
        $("#delete-complex-group-modal .group-member-block").hide();
        $("#delete-complex-group-modal .modal-title").text("Delete Group");
      } else {
        $("#delete-complex-group-modal .group-member-block").show();
      }
    });

    $("#delete-bundle-group-btn").on("click", function() {

    })
  }

  var handleLongPressGroup = function(elements) {
    $("#pills-chat, #pills-groups, #pills-zero-groups").delegate("ul.room-list li", "long-press", function() {
      const self = this;
      setTimeout(function() {
        enableSelectGroup();
        $(self).click();
      }, 10);
    })
  }

  var handleSingleClear = function() {
    $(".clear-group-btn").on("click", function() {
      let currentUserId = parseInt(sessionStorage.getItem('current_user_id'));
      const roomId = groupSelectedIds[0]
      let roomData = roomList.find(element => element.id === roomId);
      const roomName = $(`#conversation-no-${roomId}`).find('.room-name').text();
      $("#clear-history-modal input[name='room_id']").val(roomId);
      renderModalConfirmClearAll(roomData, roomName, currentUserId)
    })

    $("#clear-history-btn").on("click", function() {
      closeSelect();
    })
  }

  // used on conversation.js
  renderModalConfirmClearAll = function(roomData, roomName, currentUserId) {
    roomName = htmlEncode(roomName)

    if (roomData.roomType == 'broadcast') {
      $("#confirm-clear-message-content").html(`<div class="mb-3">
        Are you sure you want to clear the chat history?
      </div>`);
    } else {
      $("#confirm-clear-message-content").html(`<div class="mb-3">
        Are you sure you want to clear ${ roomData.roomType === 'dirrect' ? ("your chat history with " + roomName) : ("the chat history in " + roomName) }?
      </div>${renderAlsoClear(roomData, roomName, currentUserId)}`);
    }
  }

  var renderAlsoClear = function(roomData, roomName, currentUserId) {
    if (currentUserId === roomData.adminId || roomData.roomType === 'dirrect') {
      return `<div class="mb-3">
        <label>
          <input type="checkbox" class="form-check-input" name="with_participant" value="1">
          &nbsp;Also clear history for ${ roomData.roomType === 'dirrect' ? roomName : "your group's participants" }
          ${ currentUserId === roomData.adminId && roomData.roomType !== 'dirrect' ? '(Only you can do it as admin of group)' : '' }
        </label>
      </div>`;
    }
    return '';
  }

  var handleSingleDelete = function() {
    $(".delete-group-btn").on("click", function() {
      let currentUserId = parseInt(sessionStorage.getItem('current_user_id'));
      const roomId = groupSelectedIds[0]
      let roomData = roomList.find(element => element.id === roomId);
      const roomName = $(`#conversation-no-${roomId}`).find('.room-name').text();

      $("#delete-history-modal input[name='room_id']").val(roomId);
      let deleteLabel = "";
      switch (roomData.roomType) {
        case 'dirrect':
          deleteLabel = 'Delete History';
          break;
        case 'broadcast':
          deleteLabel = 'Delete Broadcast';
          break;
        default:
          deleteLabel = roomData.adminId === currentUserId ? 'Destroy Group' : 'Leave Group'
      }

      $("#delete-history-modal-label").text(deleteLabel);
      renderModalConfirmDeleteGroup(roomData, roomName, currentUserId);
    })

    $('#delete-history-btn').on('click', function(e) {
      $("#delete-group-modal").modal("hide");
      $(".cancel-select-group").hide();
      $(".tab-name").show();
      $(".chat-message-list .round").removeClass("open")
      $(".group-top-nav").show();
      $(".group-selected-number").html("");
      $(".clear-group-btn").hide();
      $(".delete-group-btn").hide();
      $(".set-unpin-btn").hide();
      $(".set-pin-btn").hide();
      gotoConversation = true;
      groupSelectedIds = [];
    });
  }

  // used on conversation.js
  renderModalConfirmDeleteGroup = function(roomData, roomName, currentUserId) {
    roomName = htmlEncode(roomName)

    var result = '';
    if (roomData.roomType === 'dirrect') {
      result = `<div class="mb-3">
        Are you sure you want to delete the chat with ${roomName}?
      </div>
      <div class="mb-3">
        <label>
          <input type="checkbox" class="form-check-input" name="with_participant" value="1">
          &nbsp;Also delete history for ${roomName}
        </label>
      </div>`
    } else if (roomData.roomType === 'broadcast') {
      result = `<div class="mb-3">
        Are you sure you want to destroy this broadcast?
      </div>`
    } else {
      result = `<div class="mb-3">${renderConfirmDeleteForGroup(roomData, roomName, currentUserId)}</div>`
    }
    $("#confirm-delete-group").html(result);
  }

  var renderConfirmDeleteForGroup = function(roomData, roomName, currentUserId) {
    if (roomData.adminId === currentUserId) {
      return `Are you sure you want to destroy the group ${roomName}?`
    } else {
      return `Are you sure you want to leave the group ${roomName}?`
    }
  }

  var handleDelete = function() {
    $(".delete-bundle-group-btn").on("click", function() {
      var roomType;

      $.ajax({
        type: "POST",
        url: "/api/v1/rooms/bundle_delete",
        data: {room_ids: groupSelectedIds},
        async: true,
      });

      $("#delete-group-modal").modal("hide");
      $("#delete-complex-group-modal").modal("hide");

      $(".cancel-select-group").hide();
      $(".tab-name").show();
      $(".chat-message-list .round").removeClass("open")
      $(".group-top-nav").show();
      $(".group-selected-number").html("");
      $(".clear-group-btn").hide();
      $(".delete-group-btn").hide();
      $(".set-unpin-btn").hide();
      $(".set-pin-btn").hide();
      gotoConversation = true;

      groupSelectedIds.forEach(function(roomId) {
        let room_index = roomList.findIndex(element => element.id === roomId)
        roomList.splice(room_index, 1)
        $("#conversation-no-" + roomId).remove()
      });

      groupSelectedIds = []

      if (activedTab === "pills-chat") {
        roomType = "dirrect";
      } else if (activedTab === "pills-groups") {
        roomType = "group_chat";
      } else if (activedTab === "pills-zero-groups") {
        roomType = "zero_group";
      }
      handleInitMessageGroup();
      reloadStatistic(roomType);
    })
  }

  var handleClear = function() {
    $("#clear-group-history-btn").on("click", function() {
      $.ajax({
        type: "POST",
        url: "/api/v1/rooms/bundle_clear",
        data: {room_ids: groupSelectedIds},
        async: true,
      });

      $("#clear-group-modal").modal("hide");

      groupSelectedIds.forEach(function(roomId) {
        let conversations = roomList.find(element => element.id === roomId).conversations
        conversations.splice(0, conversations.length);

        $("#conversation-no-" + roomId).find('.unread-message span').html('')
        $("#conversation-no-" + roomId).find('.chat-user-message').removeClass('chat-user-message-unread')
        $("#conversation-no-" + roomId).find('.chat-user-message').html('History was cleared')
      });
      closeSelect();

      if (activedTab === "pills-chat") {
        roomType = "dirrect";
      } else if (activedTab === "pills-groups") {
        roomType = "group_chat";
      } else if (activedTab === "pills-zero-groups") {
        roomType = "zero_group";
      }
      reloadStatistic(roomType);
    })
  }

  var handleNavBar = function() {
    $("ul.nav-pills a").on("click", function() {
      $("input[checked='checked']").click();
      $(".cancel-select-group").hide();
      $(".tab-name").show();
      $(".chat-message-list .round").removeClass("open")
      $(".group-top-nav").show();
      gotoConversation = true;

      resetSearchBox();
    })
  }

  var handleEnableSelectGroup = function() {
    $(".start-select-group-btn").on("click", function() {
      enableSelectGroup();
    })
  }

  var enableSelectGroup = function() {
    activedTab = $(".tab-pane.active").attr("id");
    gotoConversation = false;
    $(`#${activedTab} .chat-message-list .round`).addClass("open")
    $(`.side-menu .tab-name`).hide()
    $(`.side-menu .cancel-select-group`).show();
    $(".group-top-nav").hide();
  }

  var handleCloseSelectGroup = function() {
    $(".cancel-select-group").on("click", function() {
      closeSelect();
    })
  }

  var closeSelect = function() {
    $("input[checked='checked']").click();
    $(".chat-message-list .round").removeClass("open")
    $(".cancel-select-group").hide();
    $(".tab-name").fadeIn(500);
    $(".group-top-nav").show();
    gotoConversation = true;
  }

  var handleSelectGroup = function(el) {
    const groupId = parseInt($(el).attr('id').substring(16));

    if ($(el).find('.room-check').attr("checked") === "checked") {
      $(el).find('.room-check').attr("checked", false)
      $(el).removeClass("clicked");

      let pos = groupSelectedIds.findIndex(dt => dt === groupId)
      groupSelectedIds.splice(pos, 1);
    } else {
      $(el).addClass("clicked");
      $(el).find('.room-check').attr("checked", true);
      groupSelectedIds.push(groupId);
    }

    let countChoose = groupSelectedIds.length;
    handleTopbar(countChoose, activedTab);
    handleClearDeleteBtn(countChoose, activedTab);
    handleShowButtonPin(countChoose);
  }

  var handleShowButtonPin = function(countChoose) {
    if (countChoose == 0) {
      $(".set-unpin-btn").hide();
      $(".set-pin-btn").hide();
    } else {
      if ($(".room-list li.clicked .is-unpin").length > 0) {
        $(".set-pin-btn").show();
        $(".set-unpin-btn").hide();
      } else {
        $(".set-pin-btn").hide();
        $(".set-unpin-btn").show();
      }
    }
  }

  var handleClearDeleteBtn = function(countChoose, activedTab) {
    if (countChoose === 0) {
      $(".clear-group-btn").hide();
      $(".delete-group-btn").hide();
    } else {
      $(".clear-group-btn").show();
      $(".delete-group-btn").show();
      if (countChoose === 1){
        $(".delete-group-btn").attr("data-bs-target", "#delete-history-modal");
        $(".clear-group-btn").attr("data-bs-target", "#clear-history-modal");
      } else {
        if (activedTab === 'pills-chat') {
          $(".delete-group-btn").attr("data-bs-target", "#delete-group-modal");
        } else {
          $(".delete-group-btn").attr("data-bs-target", "#delete-complex-group-modal");
        }
        $(".clear-group-btn").attr("data-bs-target", "#clear-group-modal");
      }
    }
  }

  var handleTopbar = function(countChoose, activedTab) {
    if (countChoose === 0) {
      $('.group-selected-number').html("");
      closeSelect();
    } else {
      $('.group-selected-number').html(countChoose);
    }
  }

  var handleInitMessageGroup = function() {
    if ($("ul.chat-user-list li").length == 0) {
      $(".init-message-chat-tab").show()
      $("#pills-chat .chat-message-list").hide()
    } else {
      $(".init-message-chat-tab").hide()
      $("#pills-chat .chat-message-list").show()
    }

    if ($("ul.chat-group-list li").length == 0) {
      $(".init-message-group-tab").show()
      $("#pills-groups .chat-message-list").hide()
    } else {
      $(".init-message-group-tab").hide()
      $("#pills-groups .chat-message-list").show()
    }

    if ($("ul.chat-zero-group-list li").length == 0) {
      $(".init-message-zero-group-tab").show()
      $("#pills-zero-groups .chat-message-list").hide()
    } else {
      $(".init-message-zero-group-tab").hide()
      $("#pills-zero-groups .chat-message-list").show()
    }
  }
}).call(this);
