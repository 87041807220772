(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleUploadPhoto();
    handleViewPhotoOnConv();
    handleCloseVideoOnConv();
    hanldeSaveVideoOnConv();
    handlePickPhoto();
  }

  // handle upload photo/video on conversation vault
  var handleUploadPhoto = function() {
    $("#upload-photo-form").submit(function(e) {
      uploadData();
      return false;
    });
  }

  async function uploadData() {
    let loading = true;
    // $(".preload-encrypt").show();
    var result;
    const datastring = $("form#upload-photo-form").serialize();
    var loadingContent = $(`<div class="gallery-column">
      <img class="lazy data-enlargeable" src="/images/lazyload-encrypt.gif">
    </div>`);
    $('#attachment-chat .attachment-camera').after(loadingContent);

    $('#attachment-camera-chat').removeClass('attachment-camera-chat-show');
    $("#attachment-camera-chat .simplebar-content-wrapper").scrollTop(0);
    $('#attachment-tab-gallery').addClass('checked');
    $(`.gallery-tab`).show();
    $('#attachment-camera-chat .gallery-screenshot').hide();
    $('#attachment-camera-chat .gallery-camera').show();
    $('#attachment-camera-chat #screenshot').html('');
    $('#attachment-camera-chat #attach-file-url').val('');
    $('#attachment-camera-chat #file-type').val('');

    await $.ajax({
      url: "/api/v1/vaults/upload_file",
      type: "POST",
      async: true,
      data: datastring,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      loadingContent.remove();
      addPhotoBlock(result)
      // $(".preload-encrypt").hide();
    }
  }

  // handle view video on conversation
  var handleViewPhotoOnConv = function() {
    $("#my-chat-history").delegate(".each-message-photo .view-photo", "click", function() {
      const type = $(this).find("video").length > 0 ? 'video' : 'photo'

      if (type == 'video') {
        const photoPath = $(this).attr('raw_image');
        $("#open-photo-file img").hide();
        $("#open-photo-file .video-conversation-block").show();

        $("#open-photo-file .video-conversation-block").html(`<video autoplay controls style="width: 100%">
          <source class="video-src" src="${photoPath}" type="video/webm">
        </video>`)
        $(".layout-wrapper").hide();
        $("#open-photo-file").show();
      } else {
        // handle view photo on conversation
        // handle download photo on conversation
        const roomId = $(this).attr('data-fancybox');
        Fancybox.bind(`[data-fancybox="${roomId}"]`, {
          Toolbar: {
            items: {
              download: {
                tpl: '<button class="f-button" title="{{DOWNLOAD}}" data-fancybox-download><svg><path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2M7 11l5 5 5-5M12 4v12"/></svg></button>',
                click: () => {
                  duplicateData(Fancybox.getSlide().src, "image");
                }
              }
            },
            display: {
              left: [
                "infobar",
              ],
              middle: [],
              right: [
                "slideshow",
                "download",
                "thumbs",
                "close",
              ],
            }
          }
        });
      }
    })
  }

  // handle close video on conversation
  var handleCloseVideoOnConv = function() {
    $("#open-photo-file .close-btn").on("click", function() {
      if ($(".video-conversation-block video").length > 0) {
        $(".video-conversation-block video")[0].pause()
      }
      $("#open-photo-file").hide();
      $(".layout-wrapper").show();
    })
  }

  // handle download video on conversation
  var hanldeSaveVideoOnConv = function() {
    $(".open-photo-main #save-photo").on("click", function() {
      const type = "video"
      const photoPath = $("#open-photo-file .video-src").attr("src");
      duplicateData(photoPath, type);
    })
  }

  async function duplicateData(photoPath, type) {
    $(".preload-encrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/vaults/duplicate",
      type: "POST",
      data: { photoPath: photoPath, type: type },
      async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      type == 'video' ? addVideoBlock(result) : addPhotoBlock(result)
      $(".preload-encrypt").hide();
      showToast("Save to gallery completed!")
    }
  }

  // choose photo/video on conversation vault
  var handlePickPhoto = function() {
    $("#listing-gallery").delegate(".gallery-checkbox", "click", function() {
      choosePhoto(this);
    })
  }

  var choosePhoto = function(el) {
    var checked = $('#attachment-section .gallery-checkbox:checked').length;
    var id = el.id.split('gallery-checkbox-')[1];
    if ($(el).prop('checked')) {
      choose_attach_file_id.push(id);
    } else {
      choose_attach_file_id = choose_attach_file_id.filter(i => i != id);
    }

    if (checked > 0) {
      $('.attachment-chat-section').show();
      $('.attachment-tab').hide();
      $('.attachment-choose-amount').html(checked);
    } else {
      $('.attachment-chat-section').hide();
      $('.attachment-tab').show();
      $('.attachment-choose-amount').html('');
    }
    $("#attach-file-ids").val(choose_attach_file_id)
  }

  var addVideoBlock = function(result) {
    var galleryContent = `<div class='gallery-column'>
      <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
      <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
      <video lazy="true" class="lazy data-enlargeable each-gallery-video" raw_image="${result.rawImage}" src="${result.thumb}"></video>
      <label class="is-video">Video</label>
    </div>`;
    $('#attachment-chat .attachment-camera').after(galleryContent);

    var vaultGalleryContent = `<div class='gallery-column'>
      <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
      <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
      <video lazy="true" class="lazy data-enlargeable each-gallery-video" raw_image="${result.rawImage}" src="${result.thumb}"></video>
      <label class="is-video">Video</label>
    </div>`;
    $('#pills-vaults .attachment-camera').after(vaultGalleryContent);
  }

  var addPhotoBlock = function(result) {
    var galleryContent = `<div class='gallery-column'>
      <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
      <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
      <a class="fancybox-all" data-fancybox="gallery" data-caption="${result.createdAt}" data-src="${result.rawImage}">
        <img loading="lazy" src="${result.thumb}">
      </a>
    </div>`;
    $('#attachment-chat .attachment-camera').after(galleryContent);

    var vaultGalleryContent = `<div class='gallery-column'>
      <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
      <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
      <a class="fancybox-all" data-fancybox="gallery-vault" data-caption="${result.createdAt}" data-src="${result.rawImage}">
        <img loading="lazy" src="${result.thumb}">
      </a>
    </div>`;
    $('#pills-vaults .attachment-camera').after(vaultGalleryContent);
  }
}).call(this);
