(function() {
  const attachment_to_top = () => $(".attachment-tabs .simplebar-content-wrapper").scrollTop(0);

  $(document).on('turbolinks:load', function() {
    // get page elements
    var videos = document.getElementsByClassName("gallery-video");
    // var btnScreenshot = document.getElementById("btnScreenshot");
    var screenshotContainer = $("#attachment-camera-chat #screenshot")
    var canvas = document.getElementById("canvas");
    var attachFileUrl = $("#attachment-camera-chat #attach-file-url");
    var fileType = $("#attachment-camera-chat #file-type");

    // video constraints
    var constraints = {
      video: {
        width: { ideal: 1280 },
        height: { ideal: 960 }
      },
      audio: {
        sampleRate: 48000,
        channelCount: 1,
        volume: 0,
        echoCancellation: true,
        noiseSuppression: true,
      },
    };

    // use front face camera
    let useFrontCamera = false;

    // current video stream
    let videoStream;
    let recorder;

    // take screenshot
    $("#btnScreenshot").on("click", function () {
      screenShot();
      $('.gallery-camera').hide();
      $('.gallery-screenshot').show();

      $(".flash-light-btn").removeClass("active")
      videoStream.getVideoTracks()[0].applyConstraints({
        advanced: [{torch: false}]
      });
    });

    // screenshot
    var screenShot = function() {
      var img = document.createElement("img");
      canvas.width = videos[0].videoWidth;
      canvas.height = videos[0].videoHeight;
      canvas.getContext("2d").drawImage(videos[0], 0, 0);
      img.src = canvas.toDataURL("image/jpeg");
      screenshotContainer.prepend(img);
      attachFileUrl.val(img.src);
      fileType.val('image');
    }

    // stop video stream
    var stopVideoStream = function() {
      if (videoStream) {
        videoStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    }

    // initialize
    var initializeCamera = async function() {
      stopVideoStream();
      constraints.video.facingMode = useFrontCamera ? "user" : "environment";

      try {
        videoStream = await navigator.mediaDevices.getUserMedia(constraints);

        videos.forEach(function(video) {
          video.srcObject = videoStream;
          video.muted = true
          video.play();
        })
      } catch (err) {

      }
    }

    // Lazy load image
    function lazyLoadImg() {
      setTimeout(function() {
        $("img.lazy").lazyload({
          effect: "fadeIn"
        })
      }, 200);
    }

    // Attach file
    $('.attachment-chat-open').on('click', function() {
      lazyLoadImg();

      $("#attachment-section input[name=message_type]").val("image");
      $('.attachment-chat').addClass('attachment-chat-show');
      $("#attachment-section .attachment-chat .simplebar-content-wrapper").scrollTop(0);
      $('#attachment-tab-gallery').addClass('checked');
      $("#attachment-section .gallery-tab").show();
      $('#attachment-section .attachment-tab').show();
      attachment_to_top();

      $('body').bind('touchmove', function(e) {
        lazyLoadImg();
      });
    })

    $('#attachment-chat .attachment-chat-remove').on('click', function() {
      $('.attachment-chat').removeClass('attachment-chat-show');
      $('.attachment-tab-child').removeClass('checked');
      $('#attachment-section .attach-title').hide();
      $('.attachment-chat-section').hide();
      $("#attachment-section .file-tab, #attachment-section .secured-book-tab").hide();
      $('.attachment-choose-amount').html('');
      $('#attachment-section .gallery-checkbox').prop('checked', false);
      choose_attach_file_id = [];
    })

    // handle start camera on conversation
    $('#attachment-chat .attachment-camera').on('click', function() {
      handleTakeCamera()
    });

    function handleTakeCamera() {
      let allowMic = true;
      let allowCam = true;

      try {
        const camPermission = Permission.checkCamPermission()
        if (!camPermission) {
          allowCam = false;
          Permission.cameraPermission();
          return false;
        }
      } catch (err) {}

      try {
        const micPermission = Permission.checkMicPermission()
        if (!micPermission) {
          allowMic = false;
          Permission.voicePermission();
          return false;
        }
      } catch (err) {}

      if (allowMic && allowCam) {
        $('.gallery-screenshot').hide();
        $('.attachment-tabs').hide();
        $('.attachment-camera-chat').addClass('attachment-camera-chat-show');

        $(".attachment-camera-chat .switch-video-photo").show();
        $(".attachment-camera-chat .switch-video-photo .take-photo-option").addClass('active')
        $(".attachment-camera-chat .switch-video-photo .take-video-option").removeClass('active')

        $(".attachment-camera-chat #btnScreenshot").show();
        $(".attachment-camera-chat #btnRecordVideo").hide();
        $(".attachment-camera-chat #btnStopAndSaveVideo").hide();

        initializeCamera();
      }
    }

    $(".attachment-camera-chat #btnRecordVideo").on("click", function () {
      $(".attachment-camera-chat .switch-video-photo").hide();
      $(".attachment-camera-chat #btnRecordVideo").hide();
      $(".attachment-camera-chat #btnStopAndSaveVideo").show();

      recorder = new MediaRecorder(videoStream, {
        mimeType: 'video/webm'
      });
      recorder.start();
    });


    // handle stop camera on conversation
    $('#attachment-camera-chat .gallery-camera .attachment-camera-section-remove').on('click', function() {
      $('.attachment-camera-chat').removeClass('attachment-camera-chat-show');
      $(`.gallery-tab`).show();
      attachment_to_top();

      $(".flash-light-btn").removeClass("active")
      videoStream.getVideoTracks()[0].applyConstraints({
        advanced: [{torch: false}]
      });

      stopVideoStream();
    })

    $("#attachment-camera-chat #screenshot-save").on("click", function() {
      stopVideoStream();
    })

    $('#attachment-camera-chat .gallery-screenshot .attachment-camera-section-remove').on('click', function() {
      $('.gallery-screenshot').hide();
      $('.gallery-camera').show();
      $('#screenshot').html('');
    })

    $('#attachment-chat .attachment-tab-child').click(function() {
      var id = this.id.split('attachment-tab-')[1];
      $('.attachment-tab-child').removeClass('checked');
      $(this).addClass('checked');
      $('#attachment-section .attachment-tabs').hide();
      $('#attachment-section .attach-title').hide();
      $(`#attachment-section .${ id }-tab`).show();

      var type;

      switch(id) {
      case "gallery":
        type = "image"
        break;
      case "file":
        type = "file"
        break;
      case "secured-book":
        type = "csv"
        break;
      }

      $("#attachment-section input[name=message_type]").val(type);
    })

    $("#attachment-camera-chat .flash-light-btn").on("click", function() {
      if($(this).hasClass("active")) {
        $(this).removeClass("active")
        videoStream.getVideoTracks()[0].applyConstraints({
          advanced: [{torch: false}]
        });
      } else {
        $(this).addClass("active")
        videoStream.getVideoTracks()[0].applyConstraints({
          advanced: [{torch: true}]
        });
      }
    })

    $(".attachment-camera-chat #btnStopAndSaveVideo").on("click", function () {
      recorder.ondataavailable = e => {
        uploadVideo(e.data)
      };
      recorder.stop();

      $(".flash-light-btn").removeClass("active")
      videoStream.getVideoTracks()[0].applyConstraints({
        advanced: [{torch: false}]
      });
      stopVideoStream();
    });

    async function uploadVideo(data) {
      let loading = true
      var result
      var loadingContent = $(`<div class="gallery-column">
        <video class="lazy data-enlargeable each-gallery-video" raw_image="${URL.createObjectURL(data)}" src="${URL.createObjectURL(data)}"></video>
        <label class="is-video">Uploading</label>
      </div>`);

      $('#attachment-chat .attachment-camera').after(loadingContent);
      $('#attachment-camera-chat').removeClass('attachment-camera-chat-show');
      $("#attachment-camera-chat .simplebar-content-wrapper").scrollTop(0);
      $('#attachment-tab-gallery').addClass('checked');
      $(`.gallery-tab`).show();
      $('#attachment-camera-chat .gallery-screenshot').hide();
      $('#attachment-camera-chat .gallery-camera').show();
      $('#attachment-camera-chat #screenshot').html('');
      $('#attachment-camera-chat #attach-file-url').val('');
      $('#attachment-camera-chat #file-type').val('');

      var fd = new FormData();
      fd.append('file_type', 'video');
      fd.append('attach_file_url', data);

      await $.ajax({
        url: "/api/v1/vaults/upload_file",
        type: "POST",
        async: true,
        data: fd,
        processData: false,
        contentType: false,
        success: function(data) {
          result =  data.data;
          loading = false
        }
      });

      if (!loading) {
        loadingContent.remove();
        var galleryContent = `<div class='gallery-column'>
          <input type='checkbox' class='gallery-checkbox' id='gallery-checkbox-${result.id}' />
          <label for='gallery-checkbox-${result.id}' class='gallery-label'></label>
          <video lazy="true" class="lazy data-enlargeable each-gallery-video" raw_image="${result.rawImage}" src="${result.thumb}"></video>
          <label class="is-video">Video</label>
        </div>`;
        $('#attachment-chat .attachment-camera').after(galleryContent);

        var vaultGalleryContent = `<div class='gallery-column'>
          <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
          <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
          <video lazy="true" class="lazy data-enlargeable each-gallery-video" raw_image="${result.rawImage}" src="${result.thumb}"></video>
          <label class="is-video">Video</label>
        </div>`;
        $('#pills-vaults .attachment-camera').after(vaultGalleryContent);

        // $(".preload-encrypt").hide();
      }
    }
  });
}).call(this);
