(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleOpenNote();
  }

  var handleOpenNote = function() {
    $("#user-chat-show").delegate("a[data-bs-target='#user-note-modal']", "click", function() {
      const roomId = $('.note-view').attr('room_id');
      loadData(roomId)
    })
  }

  async function loadData(roomId) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true
      
    await $.ajax({
      url: `/api/v1/notes/get_data?room_id=${roomId}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data.body;
        loading = false
      }
    });

    if (!loading) {
      $(".preload-decrypt").hide();
      $(".note-view .field-text").val(result);
    }
  }
}).call(this);