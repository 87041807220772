(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleHideShowPassword();
  }

  var handleHideShowPassword = function() {
    $(".password-icon").on("click", function() {
      if ($("#password-switch")[0].type === 'text') {
        $("#password-switch")[0].type = "password";
        $(".p-show").hide();
        $(".p-hide").show();
      } else {
        $("#password-switch")[0].type = "text";
        $(".p-show").show();
        $(".p-hide").hide();
      }
      $("#password-switch").focus();
    })
  }
}).call(this);