import consumer from "./consumer"

consumer.subscriptions.create({
  channel: "NotificationChannel",
  device_token: readCookie("device_token_ws"),
}, {
  connected() {
    console.log("Connected to the NotificationChannel!");
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    console.log("disconnected to the NotificationChannel!");
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (typeof AndroidFunction !== "undefined") {
      AndroidFunction.showNotification(data.message);
    }
  },
})

