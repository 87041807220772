(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    checkAndInitData();
    handleSelectOption();
    handleSetLifetime();
  }

  var checkAndInitData = function() {
    let lifeTime = localStorage.getItem('lifetime');
    if (!lifeTime || lifeTime == 0) {
      localStorage.setItem('lifetime', 259200);
    }
    lifeTime = localStorage.getItem('lifetime');
    $('.lifetime').val(lifeTime);

    if (lifeTime && lifeTime > 0) {
      setDefaultData(lifeTime);
      const lifetimeDurationInput = $("input[name='lifetime-duration']");
      const days = lifetimeDurationInput.attr('days')
      const hours = lifetimeDurationInput.attr('hours')
      const mins = lifetimeDurationInput.attr('mins')
      const lifeTimeText = short_translate(days, hours, mins);
      const lifeTimeTextOnConv = short_translate(days, hours, mins, true);

      $('.setting-lifetime-display').text(lifeTimeText);
      $('.lifetime-display-btn').text(lifeTimeTextOnConv);

      $(".check-lifetime-condition").prop("checked", true)
      $(".lifetime-duration-group").show();
    } else {
      $(".check-lifetime-condition").prop("checked", false)
      $(".lifetime-duration-group").hide();
    }
  }

  var handleSelectOption = function() {
    $(".check-lifetime-condition").on("click", function() {
      if($(".check-lifetime-condition").is(":checked")) {
        $(".lifetime-duration-group").show();
        $(".lifetime-duration-group").empty();
        $(".lifetime-duration-group").append(`<input type="hidden" name="lifetime-duration" value="86400"/>
          <span id="lifetime-duration-label" style="font-size: 14px;font-weight: 300;">Your message will self-destruct 1 day</span>`);
        localStorage.setItem('lifetime', 86400);
        setDefaultData(86400);
      } else {
        $(".lifetime-duration-group").hide();
        localStorage.setItem('lifetime', 0);
      }
    })
  }

  var handleSetLifetime = function() {
    $(".set-input-lifetime").on("click", function() {
      const lifeTime = parseInt($('input[name=lifetime-duration]').val());
      if (lifeTime && lifeTime > 0) {
        $("#lifetime-error-text").hide();
        const lifetimeDurationInput = $("input[name='lifetime-duration']");
        const lifeTime = lifetimeDurationInput.attr('value')
        const days = lifetimeDurationInput.attr('days')
        const hours = lifetimeDurationInput.attr('hours')
        const mins = lifetimeDurationInput.attr('mins')
        const lifeTimeText = short_translate(days, hours, mins);
        const lifeTimeTextOnConv = short_translate(days, hours, mins, true);

        localStorage.setItem('lifetime', lifeTime);
        $('.setting-lifetime-display').text(lifeTimeText);
        $('.lifetime-display-btn').text(lifeTimeTextOnConv);
        $('.lifetime').val(lifeTime);
        $('#set-lifetime-message-modal').modal('toggle');
      } else {
        $("#lifetime-error-text").show();
        // $('.setting-lifetime-display').text("Off");
        // $('.lifetime-display-btn').text("Off");
        // $('.lifetime').val('');

      }
    })
  }

  var setDefaultData = function(lifeTime) {
    $("input[name='lifetime-duration']").val(lifeTime);
    $('input[name=lifetime-duration]').durationpicker().on("change", function(){
      const days = $(this).attr("days");
      const hours = $(this).attr("hours");
      const mins = $(this).attr("mins");
      // localStorage.setItem('lifetime', $(this).val());
      $('#lifetime-duration-label').text("Your message will self-destruct " + translate(days, hours, mins));
    });

    const lifetimeDurationInput = $("input[name='lifetime-duration']");
    const days = lifetimeDurationInput.attr('days')
    const hours = lifetimeDurationInput.attr('hours')
    const mins = lifetimeDurationInput.attr('mins')
    $('#lifetime-duration-label').text("Your message will self-destruct " + translate(days, hours, mins));
  }

  var short_translate = function(days, hours, minutes, veryShort = false) {
    var units = [];
    if (days > 0 ) {
      units.push(`${days} d`);
    }
    if (hours > 0 ) {
      units.push(`${hours} h`);
    }
    if (minutes > 0 ) {
      units.push(`${minutes} m`);
    }

    var last = "";
    if ( units.length > 1 ) {
      last = " " + "and" + " " + units.pop();
    }
    if (veryShort) {
      return units[0]
    }
    return units.join( ", " ) + last;
  }

  var translate = function(days, hours, minutes) {
    var units = [];
    if (days > 0 ) {
      units.push(`${days} days`);
    }
    if (hours > 0 ) {
      units.push(`${hours} hours`);
    }
    if (minutes > 0 ) {
      units.push(`${minutes} minutes`);
    }

    var last = "";
    if ( units.length > 1 ) {
      last = " " + "and" + " " + units.pop();
    }
    return units.join( ", " ) + last;
  }
}).call(this);


