(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleViewSeen();
  }

  var handleViewSeen = function() {
    $("ul#my-chat-history").delegate("li.conversation-messages", "click", function() {
      const target = $(this).find(".seen-user-list")
      const messageId = $(this).attr("id").substring(8)
      const roomId = $("#my-chat-history").attr("room_id")

      if (target.is(":hidden")) {
        getSeenData(messageId, roomId, target)
        target.slideDown();
      } else {
        target.slideUp();
      }
    })
  }

  async function getSeenData(messageId, roomId, target) {
    await $.ajax({
      type: "GET",
      url: `/api/v1/conversations/seen_data?room_id=${roomId}&message_id=${messageId}`,
      async: true,
      success: function(data) {
        target.text((data.list || "No one") + " seen" )
      }
    });
  };
}).call(this);
