import consumer from "./consumer"

let loadCount = 0;
$(document).on('turbolinks:load', function() {
  if (typeof current_user_token === 'undefined' || current_user_token === "") {
    return;
  }

  loadCount += 1;
  if (loadCount > 1) {
    return;
  }

  consumer.subscriptions.create({
    channel: "QuestionChannel",
    user_token: current_user_token,
  }, {
    connected() {
      console.log("Connected to the secret question room!");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      const current_user_id = parseInt(sessionStorage.getItem('current_user_id'))
      if (data.type == "send_question") {
        this.processQuestion(data, current_user_id)
      } else if (data.type == "answer_question") {
        this.processAnswer(data, current_user_id)
      }
    },

    processAnswer(data, current_user_id) {
      if (data.dirrectUserId === current_user_id) {
        $(`.contact-user-id-${data.sentUserId} .room-name`).text(data.sentUserName);
        $(`.contact-user-id-${data.sentUserId} .verify-status-block`).remove();
        $(`.contact-user-id-${data.sentUserId} .user-item`).removeClass("user-profile-show");
        $(`.contact-user-id-${data.sentUserId} .user-item`).attr("onclick", `startConversationFromContact(${data.sentUserId}, '${data.sentUserName}')`)
        $(`.contact-user-id-${data.sentUserId} .inline-block`).remove()
        $(`.user-profile-${data.sentUserId} .answer-secret-quesion-block`).remove();

        $("#root-add-block .root-user-list").prepend(`
          <li class="contact-user-id-${data.sentUserId}">
            <a href="javascript:void(0)" onclick="startConversationFromContact(${data.sentUserId}, '${ data.sentUserName }')")>
              <div class="avatar-xs custom-color-${data.sentUserId}" style="background-color: ${data.sentUserColor}">
                ${ htmlEncode(data.sentUserName)[0].toUpperCase() }
              </div>
              <span class="text-truncate room-name custom-fullname-${data.sentUserId}">${ htmlEncode(data.sentUserName) }</span>
            </a>
          </li>
        `)
      }
    },

    processQuestion(data, current_user_id) {
      if (current_user_id === data.dirrectUserId) {
        if ($(".user-profile-sidebar .user-id").val() == data.sentUserId) {
          $("#answer-secret-question-form .question").text(data.friendStatus.question);
        }
        if ($(`.request-user-id-${data.sentUserId}`).length === 0) {
          $("#pills-requests .contact-list").prepend(`
            <li class="request-user-id-${data.sentUserId}">
              <input type="hidden" class="custom-color-value-${data.sentUserId}" value="${data.sentUserColor}">
              <div class="d-flex align-items-center">
                <div class="col-11 user-item user-profile-show" user_id="${data.sentUserId}">
                  <div class="room-name custom-fullname-1">${ htmlEncode(data.sentUserName) }</div>
                  <div class="inline-block">
                    <div class="verify-status-block" style="">Not Verified</div>
                    ${ data.isShowAppMember ? '<div class="app-member-btn">App Member</div>' : '' }
                    ${ data.isShowPhoneMember ? '<div class="app-member-btn">OS Member</div>' : '' }
                  </div>
                </div>

                <div class="dropdown col-1">
                  <a href="#" class="text-muted dropdown-toggle pull-right" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </a>
                  <div class="dropdown-menu dropdown-menu-end">
                    <a class="dropdown-item user-profile-show" user_id="${data.sentUserId}">View profile <i class="ri-profile-line float-end text-muted"></i></a>
                    <a class="dropdown-item block-friend-action" user_id="${data.sentUserId}">Block <i class="ri-forbid-line float-end text-muted"></i></a>
                    <a class="dropdown-item remove-friend-action" user_id="${data.sentUserId}">Remove <i class="ri-delete-bin-line float-end text-muted"></i></a>
                  </div>
                </div>
              </div>
            </li>
          `);
        }
        this.friendRequestCounter();
      }
    },
    friendRequestCounter() {
      const counter = $("#pills-requests .contact-list li").length;
      if (counter > 0) {
        $(".friend-request-counter").addClass("notify-counter");
        $(".friend-request-counter").text(counter);
        $("#pills-requests-tab").show();
      }
    }
  })
});
