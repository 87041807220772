(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleForward();
    handleForwardClick();
  }

  var handleForwardClick = function() {
    $("#listing-group-forward").delegate("ul.room-list li", "click", function(e) {
      handleClickConversation(this);
    })
  }

  var handleForward = function() {
    // when click forward button
    $('.checkbox-forward').on('click', function() {
      clickConvCount = 0;
      if (delete_message_id.length > 0) {
        delete_message_id.forEach(function(messageId) {
          let senderName = $(`#message-${messageId} .conversation-name`).text();
          if (!forwardUserNames.includes(senderName)) {
            forwardUserNames.push(senderName);
          }
        })
      }
      $('.forward-chat').addClass('forward-chat-show');
      $("#forward-chat .simplebar-content-wrapper").scrollTop(0);
    })

    // when cancel forward
    $('.forward-chat-remove').on('click', function() {
      if ($('.forward-content').html()?.length > 0) {
        $('.forward-section').show();
        $('.chat-conversation').addClass('forward-section-open');
        rollToBottom();
      }

      $('.forward-chat').removeClass('forward-chat-show');
    })

    $('body').delegate('.close-forward-section', 'click', function() {
      $('.forward-section').remove();
      $('#chat-send').hide()
      $(".conversation-tool-button").show()
    })
  }
}).call(this);
