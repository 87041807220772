import consumer from "./consumer"

let loadCount = 0;
$(document).on('turbolinks:load', function() {
  if (typeof current_user_token === 'undefined' || current_user_token === "") {
    return;
  }

  loadCount += 1;
  if (loadCount > 1) {
    return;
  }

  consumer.subscriptions.create({
    channel: "NoteChannel",
    user_token: current_user_token,
  }, {
    connected() {
      console.log("Connected to the note!");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      const current_user_id = parseInt(sessionStorage.getItem('current_user_id'))
      const roomId = $('.note-view').attr('room_id');

      if (data.type === "write") {
        if (data.room_id == roomId && data.participant_ids.includes(current_user_id)) {
          $(".note-view .field-text").val(data.body);
          $(".note-view .field-text").attr("disabled", false)
          $(".note-view label").text("Note");
        }
        return true;
      }

      if (data.type === "typing") {
        if (data.room_id == roomId && data.participant_ids.includes(current_user_id)) {
          $(".note-view .field-text").attr("disabled", true)
          $(".note-view label").text(`${data.author} is typing...`);
        }
      }
    }
  })
});