(function() {
  clickChekTerm = function() {
    if($(".check-condition-term").prop("checked") && $(".check-condition-privacy").prop("checked")) {
      $("a.continue-btn").addClass("btn-active")
    } else {
      $("a.continue-btn").removeClass("btn-active")
    }
  }

  openModal = function(modelID) {
    $(modelID).fadeIn(500);
    $(modelID).css("display", "flex");
  }

  closeModal = function(modelID) {
    $(modelID).fadeOut(500);
  }
}).call(this);
