(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    inactivityTime();
  }

  var removeUserSession = function() {
    $.ajax({
      url: '/remove_session',
      type: "DELETE",
      async: true,
      error: function(event) {
        setTimeout(function(){
          removeUserSession()
        }, 5000);
      }
    });
  }

  var inactivityTime = function () {
    if ($("#login-page").length === 0) {
      return
    }

    var now = new Date();
    var nextTime = moment(now).add(timeToLive, 'ms').toDate();

    var alreadyLogout = false;
    // DOM Events
    resetTimer()
    var events = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
    events.forEach(function(name) {
     document.addEventListener(name, resetTimer, true);
    });
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        resetTimer()
      }
    });

    function logout() {
      if (alreadyLogout) {
        return
      }

      $(".modal").modal("hide")
      $("#login-page").show()
      $(".layout-wrapper").hide();

      removeUserSession()
      alreadyLogout = true;
    }

    function resetTimer() {
      if (new Date() > nextTime) {
        logout()
      }

      now = new Date();
      nextTime = moment(now).add(timeToLive, 'ms').toDate();
    }
  };
}).call(this);



