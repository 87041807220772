// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("moment-timezone-all")


import moment from 'moment'
window.moment = moment

import { Fancybox } from "@fancyapps/ui";
window.Fancybox = Fancybox

import "@fancyapps/ui/dist/fancybox/fancybox.css";
// js
import 'plugins/bootstrap.bundle.min.js'
import 'plugins/simplebar.min.js'
import 'plugins/long-press-event.min.js'
import 'plugins/choose-color.js'
import 'plugins/jquery.lazyload.js'
import 'plugins/jquery.durationpicker.min.js'
import 'plugins/jquery.syotimer.js'
import 'plugins/app.js'
import 'plugins/ripple.js'

import 'customs/common.js'
import 'customs/signup.js'
import 'customs/copy_chek_id.js'
import 'customs/conversation.js'
import 'customs/forward.js'
import 'customs/reply.js'
import 'customs/note.js'
import 'customs/view_profile.js'
import 'customs/edit_conversation.js'
import 'customs/group.js'
import 'customs/camera.js'
import 'customs/secret_question.js'
import 'customs/contact.js'
import 'customs/bottom_couter.js'
import 'customs/copy_message.js'
import 'customs/edit_group.js'
import 'customs/polls.js'
import 'customs/message_template.js'
import 'customs/lifetime.js'
import 'customs/wallet.js'
import 'customs/hide_show_password.js'
import 'customs/recording.js'
import 'customs/vault_photo.js'
import 'customs/vault_note.js'
import 'customs/vault_main.js'
import 'customs/broadcast.js'
import 'customs/handle_send_message.js'
import 'customs/pin_room.js'
import 'customs/search_group.js'
import 'customs/vault_conversation.js'
import 'customs/payment.js'
import 'customs/sheet.js'
import 'customs/vault_books.js'
import 'customs/handle_view_seen.js'
import 'customs/inactivity_time.js'
import 'customs/awake_screen.js'
import 'customs/home.js'
import 'stylesheets/application'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

