(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    viewProfile();
    closeProfile();
    handleEditName();
    handleSaveName();
    handleChooseColor();
    closePopover();
    handleCloseFriendRequestModal();
  }

  var handleCloseFriendRequestModal = function() {
    $(".confirm-friend-modal .close-btn").on("click", function() {
      $(".confirm-friend-modal").hide()
    })
  }

  var closePopover = function() {
    $('body').on('click', function (e) {
      $('[data-toggle=popover]').each(function () {
        // hide any open popovers when the anywhere else in the body is clicked
        if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            $(this).popover('hide');
        }
      });
    });
  }

  var handleChooseColor = function() {
    $('#chooseColor').sbxColorChoice({
      selecionarCor: (color) => {
        const userId = $(".user-profile-sidebar .user-id").val();
        $('.colorValue').val(color);
        $('.colorDisplay').css('background-color', color);
        // Set color for top profile header
        $(".user-profile-sidebar .top-block").css("background-color", color);

        saveColor(color, userId)
      },
      reseteCor: '#4A154B',
      // textResetColorButton: 'Custom text',
      // removePallet: '.co-hue-1, .co-hue-2, .co-hue-3, .co-hue-4'
    })
  }

  var handleEditName = function() {
    $(".fullname-block").on("click", function() {
      $(".edit-wrapper").show();
      $(".show-fullname-wrapper").hide();
      $(".fullname-input").focus();
    })
  }

  var handleSaveName = function() {
    $(".edit-fullname-block .save-icon").on("click", function() {
      $(".show-fullname-wrapper").show();
      $(".edit-wrapper").hide();
      saveName($(".fullname-input").val(), $(".user-profile-sidebar .user-id").val());
    })
  }

  var closeProfile = function() {
    $("#user-profile-hide").on("click", function() {
      $(".user-profile-sidebar").hide();
      // handle when view profile on conversation, not from contact list
      if (!$("#pills-contacts, #pills-requests").hasClass("active")) {
        $("#user-chat-show").addClass("user-chat-show");
      }
    })
  }

  var viewProfile = function() {
    const currentUserId = parseInt(sessionStorage.getItem('current_user_id'))

    $("#addContact-exampleModal").delegate(".user-profile-show", "click", function() {
      const userId = $(this).attr("user_id");
      loadData(userId, currentUserId)
    })
    $("#user-chat-show").delegate(".user-profile-show", "click", function() {
      const userId = $("#user-chat-show").attr("user_id");
      loadData(userId, currentUserId)
    })

    $("#pills-contacts, #pills-requests").delegate(".user-profile-show", "click", function() {
      const userId = $(this).attr("user_id");
      loadData(userId, currentUserId)
    })
  }

  async function saveName(fullName, userId) {
    $(".preload-encrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: "/api/v1/users/update_name",
      type: "POST",
      data: { fullname: fullName, user_id: userId },
      async: true,
      success: function(data) {
        loading = false;
        $(`.custom-fullname-${userId}`).text(data.data.customFullname);
      }
    });

    if (!loading) {
      $(".preload-encrypt").hide();
    }
  }

  async function saveColor(color, userId) {
    $(".preload-encrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: "/api/v1/users/update_color",
      type: "POST",
      data: { color: color, user_id: userId },
      async: true,
      success: function(data) {
        loading = false;
        $(`.custom-color-${userId}`).css("background-color", color);
        $(`.custom-color-value-${userId}`).val(color);
      }
    });

    if (!loading) {
      $(".preload-encrypt").hide();
    }
  }

  var handleFriendStatus = function(friendStatus, currentUserId, dirrectUserId, chekId) {
    $(".answer-secret-quesion-block").remove();
    $(".complete-secret-quesion-notify").remove();

    $("#send-secret-question-form")[0].reset();
    $(".fullname-block").show();

    if (friendStatus.status !== 'verified') {
      if (friendStatus.status === 'not_verify') {
        $("#send-secret-question-form input[name='dirrect_user_id']").val(dirrectUserId);
        $(`.user-profile-${dirrectUserId} .user-profile-desc`).before(`
        <div class="answer-secret-quesion-block" style="background-color: #ffd165;padding: 16px;color: black;display: inline-block">
          <p>Please verify your contact to ensure that your communications are secured.</p>
          <div style="float:right; font-weight: bold" data-bs-target="#verify-friend-modal" data-bs-toggle="modal">PROCESS</div>
        </div>`)

        // $("#send-secret-question-form input[name='room_id']").val(roomData.id);
      } else if (friendStatus.status === 'waiting' && friendStatus.createQuestionUserId === currentUserId) {
        $("#complete-send-verify-friend-modal .answer").text(friendStatus.answer);
        $("#complete-send-verify-friend-modal .question").text(friendStatus.question);
        $("#send-secret-question-form input[name='dirrect_user_id']").val(dirrectUserId);

        $(`.user-profile-${dirrectUserId} .user-profile-desc`).before(`
        <div class="answer-secret-quesion-block" style="background-color: #ffd165;padding: 16px;color: black;display: inline-block">
          <p>A friend request is already pending with this contact.</p>
          <div style="float:right; font-weight: bold" data-bs-target="#complete-send-verify-friend-modal" data-bs-toggle="modal">VIEW</div>
        </div>`)

        $(".fullname-block").hide();

        // $("#send-secret-question-form input[name='room_id']").val(roomData.id);
      }
    }
  }

  async function loadData(userId, currentUserId) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: `/api/v1/users/get_info?user_id=${userId}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $(".preload-decrypt").hide();

      if (result.friendStatus.status === 'waiting' && result.friendStatus.createQuestionUserId !== currentUserId) {
        handleFriendConfirm(result)
      } else {
        handleDetailProfile(result, currentUserId, userId)
      }
    }
  }

  var handleFriendConfirm = function(result) {
    $(".confirm-friend-modal").show()
    $(".confirm-friend-modal .chek-id").text(result.chekId)
    $(".confirm-friend-modal .alias").text(result.alias)
    $(".confirm-friend-modal .introduction").text(result.friendStatus.question)
    $("#answer-secret-question-form input[name='dirrect_user_id']").val(result.userId);
    $(".confirm-friend-modal .ignore-btn").attr("user_id", result.userId)
  }

  var handleDetailProfile = function(result, currentUserId, userId) {
    $(".user-profile-sidebar").addClass(`user-profile-${result.userId}`)
    $(".user-profile-sidebar .user-id").val(result.userId);
    $(".user-profile-sidebar .fullname").text(result.alias);
    $(".user-profile-sidebar .fullname").addClass(`custom-fullname-${result.userId}`);
    $(".user-profile-sidebar .fullname-input").val(result.alias);
    $(".user-profile-sidebar .chek-id-value").text(result.chekId);
    $("#user-chat-show").removeClass("user-chat-show");
    $("#addContact-exampleModal").modal("hide");
    $(".user-profile-sidebar").show();
    $(".user-profile-sidebar .top-block").css("background-color", result.color);
    $(".colorDisplay").css("background-color", result.color);

    handleFriendStatus(result.friendStatus, currentUserId, userId, result.chekId)
  }
}).call(this);
