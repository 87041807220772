export function replyMessageContent(hasReplyMessage = false, messageType, replyMessageId, ownerName, content, fileType = 'image') {
  let reply_message = ""

  if (hasReplyMessage) {
    if (messageType === "voice") {
      reply_message = `<div class='border-bottom reply-section-content' reply-id="${replyMessageId}">
        <p class="text-success">${ ownerName }</p>
        <p class="poll-icon-text"><i class="ri-mic-line chart-icon"></i> Voice message</p>
      </div>`
    } else if (messageType === "poll") {
      reply_message = `<div class='border-bottom reply-section-content' reply-id="${replyMessageId}">
        <p class="text-success">${ ownerName }</p>
        <p class="poll-icon-text"><i class="ri-pie-chart-2-line chart-icon"></i> Poll</p>
      </div>`
    } else if (messageType === "image") {
      reply_message = `<div class='border-bottom reply-section-content reply-section-content-photo' reply-id="${replyMessageId}">
        <div class="image-block">
          ${ fileType == 'video' ? `<video src="${ content }"></video>` : `<image src="${ content }" />` }
        </div>
        <div class="right-block">
          <p class="text-success">${ ownerName }</p>
          <p>Gallery</p>
        </div>
      </div>`
    } else if (messageType === "file") {
      reply_message = `<div class='border-bottom reply-section-content' reply-id="${replyMessageId}">
        <p class="text-success">${ ownerName }</p>
        <p class="poll-icon-text"><i class="ri-file-line chart-icon"></i>${ content }</p>
      </div>`
    } else if (messageType === "csv") {
      reply_message = `<div class='border-bottom reply-section-content' reply-id="${replyMessageId}">
        <p class="text-success">${ ownerName }</p>
        <p class="poll-icon-text"><i class="ri-file-excel-2-line chart-icon"></i>${ content }</p>
      </div>`
    } else {
      reply_message = `<div class='border-bottom reply-section-content' reply-id="${replyMessageId}">
        <p class="text-success">${ ownerName }</p>
        <p>${ content }</p>
      </div>`
    }
  }

  return reply_message
}

export function printLastMessage(messageType, message) {
  let lastMessage = ''

  switch(messageType) {
  case 'image':
    lastMessage = 'Photo'
    break;
  case 'voice':
    lastMessage = 'Voice message'
    break;
  case 'file':
    lastMessage = 'File'
    break;
  case 'csv':
    lastMessage = 'Secured book'
    break;
  case 'poll':
    lastMessage = 'Poll'
    break;
  default:
    lastMessage = message
  };

  return lastMessage
}

export function htmlEncode(html){
  return String(html).replace(/[\u00A0-\u9999<>\&]/gim, function(c){
    return '&#'+c.charCodeAt(0)+';';
  });
}

export function handleInitMessageGroup() {
  if ($("ul.chat-user-list li").length == 0) {
    $(".init-message-chat-tab").show()
    $("#pills-chat .chat-message-list").hide()
  } else {
    $(".init-message-chat-tab").hide()
    $("#pills-chat .chat-message-list").show()
  }

  if ($("ul.chat-group-list li").length == 0) {
    $(".init-message-group-tab").show()
    $("#pills-groups .chat-message-list").hide()
  } else {
    $(".init-message-group-tab").hide()
    $("#pills-groups .chat-message-list").show()
  }

  if ($("ul.chat-zero-group-list li").length == 0) {
    $(".init-message-zero-group-tab").show()
    $("#pills-zero-groups .chat-message-list").hide()
  } else {
    $(".init-message-zero-group-tab").hide()
    $("#pills-zero-groups .chat-message-list").show()
  }
}
