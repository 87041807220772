delete_message_id = [];
pollIds = [];
forwardUserNames = [];
choose_room_id = [];
choose_attach_file_id = [];
globalShiftTime = 0

var timeout_id = 0,
    hold_time = 1000,
    first_choose = false,
    longtouch = false,
    is_show = false,
    disable_click_flag = false;

(function() {
  showToast = function(chars) {
    $(".toast-body").text(chars);
    $(".toast-block").show();
    $('.toast').toast({ delay: 3000})
    $('.toast').toast("show")
    $(".toast-block").animate({width:'show'}, 3000);
  }

  readCookie = function(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
    }
    return null;
  }

  setCookie = function(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
  }

  onSubmitLogin = function() {
    $("#user-sigin-in .login-loading").show();
    $("#user-sigin-in .input-data").keydown(function() {
      return false;
    });

    $("#user-sigin-in .continue-btn").on("click", function() {
      return false;
    });
  }

  loadingOnSubmit = function() {
    $(".preload").show()
  }

  var startGlobe = function() {
    var container = $('.js-globe')[0];
    if (container !== undefined) {
      callGlobe(container);
    }
  }

  $(document).on('turbolinks:click',function() {
    $(".preload").show()
  });


  $(document).on('turbolinks:load', function() {
    $(".preload").hide()
    init();
  });

  var init = function() {
    handleSubmitFormRemote();
    handleClickLinkRemote();
    handleLogout()
    handleLoadingForm();
    // startGlobe();
  }

  // public method using on shared_group_action
  handleSubmitFormRemote = function() {
    $("form.handle-loading-form[data-remote='true']").on("submit",function() { $(".preload-encrypt").show() })
  }

  var handleClickLinkRemote = function() {
    $("a[data-remote='true']").on("click", function() {
      $(".preload").show()
    });
  }

  var handleLogout = function() {
    $("a.logout-btn").on("click", function() {
      $(".preload").show()
    });
  }

  var handleLoadingForm = function() {
    $("#pills-contacts").delegate("form.handle-loading-form", "submit", function() {
      $(".preload").show()
    })
  }

  rollToBottom = () => $("#user-chat-show .simplebar-content-wrapper").animate(
    {
      scrollTop: $('#my-chat-history').prop("scrollHeight")
    },
    500
  );

  rollToBottomFast = () => $("#user-chat-show .simplebar-content-wrapper").animate(
    {
      scrollTop: $('#my-chat-history').prop("scrollHeight")
    },
    0
  );

  resetSearchBox = function() {
    $(".chat-search-box input").val("");
    $("ul.room-list li").each(function () {
      $(this).show()
    });

    $("ul.contact-list li").each(function () {
      $(this).show()
    });
  }

  reloadStatistic = function(room_type) {
    if (room_type == 'dirrect') {
      var total = 0
      $(".chat-user-list .rounded-pill").each(function(e) {
        if ($(this).text()) {
          total += parseInt($(this).text());
        }
      })
      if (total > 0) {
        $("#dirrect-message-counter").text(total)
      } else {
        $("#dirrect-message-counter").removeClass("notify-counter")
        $("#dirrect-message-counter").text("")
      }
    } else if (room_type == 'group_chat') {
      var total_group = 0
      $(".chat-group-list .rounded-pill").each(function(e) {
        if ($(this).text()) {
          total_group += parseInt($(this).text());
        }
      })
      if (total_group > 0) {
        $("#group-message-counter").text(total_group)
      } else {
        $("#group-message-counter").removeClass("notify-counter")
        $("#group-message-counter").text("")
      }
    } else if (room_type == 'zero_group') {
      var total_group = 0
      $(".chat-zero-group-list .rounded-pill").each(function(e) {
        if ($(this).text()) {
          total_group += parseInt($(this).text());
        }
      })
      if (total_group > 0) {
        $("#zero-group-message-counter").text(total_group)
      } else {
        $("#zero-group-message-counter").removeClass("notify-counter")
        $("#zero-group-message-counter").text("")
      }
    }
  }

  htmlEncode = function(html){
    return String(html).replace(/[\u00A0-\u9999<>\&]/gim, function(c){
      return '&#'+c.charCodeAt(0)+';';
    });
  }

  htmlEncodeMessage = function(html){
    const emojiRegex = /^[\p{Emoji}\p{Emoji_Presentation}\p{Emoji_Modifier_Base}\p{Emoji_Modifier}\p{Emoji_Component}]*$/u;
    const numberRegex = /^\d+$/
    if (emojiRegex.test(html) && !numberRegex.test(html)) {
      return `<span style='font-size: 40px'>${html}</span>`
    } else {
      return String(html).replace(/[\u00A0-\u9999<>\&]/gim, function(c){
        return '&#'+c.charCodeAt(0)+';';
      });
    }
  }
}).call(this);
