(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleAddPollOption();
    handleDeleteOption();
    handleCreatePoll();
    handleVote();
  }

  var handleVote = function() {
    $("#my-chat-history").delegate(".poll-button", "click", function() {
      const optionId = $(this).attr("option-id");
      voteProcessServer(optionId)
    })
  }

  async function voteProcessServer(optionId) {
    let loading = true;
    $(".preload").show();

    await $.ajax({
      url: `api/v1/polls/vote?option_id=${optionId}`,
      type: "GET",
      async: true,
      success: function() {
        loading = false;
      }
    });

    if (!loading) {
      $(".preload").hide();
    }
  }

  var handleCreatePoll = function() {
    $("#create-poll-form").submit(function(e) {
      const dataString = $("form#create-poll-form").serialize();
      storeData(dataString);
      return false;
    });
  }

  async function storeData(dataString) {
    $(".preload").show();
    let loading = true

    await $.ajax({
      url: "/polls",
      type: "POST",
      data: dataString,
      async: true,
      success: function(data) {
        loading = false
      },
      error: function(event) {
        setTimeout(function(){
          storeData(dataString)
        }, 2000);
      }
    });

    if (!loading) {
      $(".preload").hide();
      resetPollForm();
    }
  }

  var handleAddPollOption = function() {
    $("#create-polls-modal .add-option-btn").on("click", function() {
      const count = $(".poll-options-group input").length

      $(".poll-options-group").append(
        `<div class="option-item">
          <input type="text" name="options[]" required="required" maxlength="20" class="form-control mb-2" id="poll-question-input" placeholder="Option ${count+1}">
          <i class="ri-close-fill delete-option"></i>
        </div>`
      )

      if (count === 4) {
        $(".add-option-btn").hide();
      }
    })
  }

  var handleDeleteOption = function() {
    $(".poll-options-group").delegate(".delete-option", "click", function() {
      $(this).parent().remove();
      const count = $(".poll-options-group input").length
      if (count < 5) {
        $(".add-option-btn").show();
      }
    })
  }

  var resetPollForm = function() {
    $("#create-polls-modal .create-poll-form")[0].reset();
    $("#create-polls-modal .option-item").remove();
    $("#create-polls-modal .add-option-btn").show();
    $("#create-polls-modal").modal("hide")
  }
}).call(this);
