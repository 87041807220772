import { handleInitMessageGroup } from './share_module'

(function() {
  var userListInfo = []
  var broadCastIds = [];

  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleNewBroadCast();
    handeClose();
    handleSelectMember();
    handleCreateBroadCast();
    handleEditBroadcast();
    handleBackToConv();
    handleSaveOnConv();
  }

  var handleSaveOnConv = function() {
    $("#edit-broadcast .continue-btn").on("click", function() {
      $("#edit-broadcast").hide();
      $("#user-chat-show").addClass("user-chat-show");
      $(".layout-wrapper").show();
      const roomId = $("#message-content input[name=room_id").val();
      updateBroadcastData(roomId);
    })
  }

  async function updateBroadcastData(roomId) {
    let totalMember;
    let roomName = "";
    let loading = true;
    $(".preload-encrypt").show();
    let roomData = roomList.find(element => element.id === parseInt(roomId))

    await $.ajax({
      url: "/api/v1/rooms/update_broadcast",
      type: "POST",
      async: true,
      data: {
        user_ids: broadCastIds,
        room_id: roomId,
        room_name: $("#edit-broadcast input[name=broadcast-list-name]").val()
      },
      success: function(data) {
        totalMember = data.data.totalMember;
        roomName = data.data.roomName;
        loading = false;
      }
    });

    if (!loading) {
      $(".preload-encrypt").hide();
      roomData.memberCount = totalMember;
      roomData.roomName = roomName;
      const memberCountText = totalMember > 1 ? "Recipients" : "Recipient";
      $(`#wrapper-conversation-${roomId} .member-count`).text(`${totalMember} ${memberCountText}`);
      $(`#wrapper-conversation-${roomId} .room-name`).text(roomName);
      $(`.conversation-no-${roomId} .room-name`).text(roomName);
    }
  }

  var handleBackToConv = function() {
    $("#edit-broadcast .back-btn").on("click", function() {
      $("#edit-broadcast").hide();
      $("#user-chat-show").addClass("user-chat-show");
      $(".layout-wrapper").show();
    })
  }

  var handleEditBroadcast = function() {
    $(".user-chat-topbar").delegate(".edit-broadcast-btn", "click", function() {
      broadCastIds = [];
      const roomId = $("#message-content input[name=room_id").val();
      $(".open-broadcast-main .selected-user-name").empty();
      $(".open-broadcast-main .user-list").empty();

      getUsers("#edit-broadcast", true, roomId);
      $("#edit-broadcast").show();
      $("#user-chat-show").removeClass("user-chat-show");
      $(".layout-wrapper").hide();
    })
  }

  var handleCreateBroadCast = function() {
    $("#open-broadcast .continue-btn").on("click", function() {
      $("#open-broadcast").hide();
      $(".layout-wrapper").show();
      createRoom(userListInfo)
    })
  }

  async function createRoom(userListInfo) {
    $(".preload-encrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/rooms/create_broadcast",
      type: "POST",
      async: true,
      data: { user_ids: broadCastIds },
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      $(".preload-encrypt").hide();

      appendConversationToChat(result);
      $(`#conversation-no-${ result.roomId }`).click();
      handleInitMessageGroup()
    }
  }

  var appendConversationToChat = function(result) {
    if ($('.chat-user-list .pin-icon:last').length) {
      $('.chat-user-list .pin-icon:last').parent().parent().parent().parent().after(renderListGroup(result, "px-2"))
    } else {
      $('.chat-user-list').prepend(renderListGroup(result, "px-2"));
    }

    $('.forward-group-list').prepend(renderListGroup(result));
  }

  var renderListGroup = function(data, classPadding = "") {
    return `<li data-long-press-delay="500" id="conversation-no-${ data.roomId }" class="${classPadding} conversation-no-${ data.roomId }">
        <div class="round">
          <input type="checkbox" disabled="disabled" class="room-check" id="checkbox-room-${ data.roomId }" />
          <label for="checkbox-room-${ data.roomId }"></label>
        </div>
        <a href="javascript:void(0)">
            <div class="d-flex align-items-center">
                <div class="chat-user-img me-3 ms-0">
                    <div class="avatar-xs">
                        <span class="avatar-title rounded-circle">
                          <i class="ri-broadcast-line"></i>
                        </span>
                    </div>
                </div>
                <div class="flex-1 overflow-hidden">
                    <h5 class="text-truncate font-size-14 mb-0 room-name">${ htmlEncode(data.groupName) }</h5>
                    <p class="chat-user-message text-truncate mb-0 chat-user-message-unread">${ htmlEncode(data.lastMessage) }</p>
                </div>
                <div class="unread-message">
                  <span class="badge badge-soft-danger rounded-pill"></span>
                </div>
            </div>
        </a>
    </li>`
  }

  var handleSelectMember = function() {
    $(".open-broadcast-main .selected-user-name").hide();
    $(".open-broadcast-main .user-list").delegate(".each-user", "click", function() {
      let id = $(this).attr("user-id");

      const user = userListInfo.find( (item) => item.id == id );
      const index = broadCastIds.indexOf(id);

      if (broadCastIds.includes(id)) {
        broadCastIds.splice(index, 1);
        $(this).find(".checked").remove();
        $(`.open-broadcast-main .selected-user-name .selected-${id}`).remove();
      } else {
        broadCastIds.push(id);
        $(this).find(".avatar-xs").append(`<label class="checked"></label>`)
        $(".open-broadcast-main .selected-user-name").append(`
          <span class="selected-${id}" style="background-color: ${user.color}">${ htmlEncode(user.fullname) }</span>
        `)
      }

      if (broadCastIds.length > 0) {
        $(".open-broadcast-main .selected-user-name").show();
        $(".open-broadcast-main .init-text").hide();
        $(".open-broadcast-main .selected-list-label").show();
        $(".open-broadcast-main .continue-btn").show();
      } else {
        $(".open-broadcast-main .selected-user-name").hide();
        $(".open-broadcast-main .init-text").show();
        $(".open-broadcast-main .selected-list-label").hide();
        $(".open-broadcast-main .continue-btn").hide();
      }
    })
  }

  var handleNewBroadCast = function() {
    $(".new-broadcast-btn").on("click", function() {
      broadCastIds = [];
      $("#open-broadcast .continue-btn").hide();
      $(".open-broadcast-main .user-list").empty();
      $(".open-broadcast-main .selected-user-name").hide();
      $(".open-broadcast-main .selected-user-name").empty();
      $(".open-broadcast-main .init-text").show();
      getUsers();
      $("#open-broadcast").show();
      $(".layout-wrapper").hide();
    })
  }

  async function getUsers(idName = "#open-broadcast", getBroadcast = false, roomId = null) {
    $(".preload-decrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/users/listing",
      type: "GET",
      async: true,
      success: function(data) {
        result = data.data;
        userListInfo = result;
        loading = false
      }
    });

    if (!loading) {
      $(`${idName} .user-list`).append(listUser(result));
      $(".preload-decrypt").hide();
      if (getBroadcast) {
        getBroadcastInfo(roomId);
      }
    }
  }

  async function getBroadcastInfo(roomId) {
    $(".preload-decrypt").show();
    let loading = true;
    var result;
    let liList = [];

    await $.ajax({
      url: `/api/v1/rooms/broadcast_info?roomId=${roomId}`,
      type: "GET",
      async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      $(".preload-decrypt").hide();
      result.userIds.forEach( (id) => {
        liList.push(`li[user-id=${id}]`);
      })
      console.log(`ul.user-list ${liList.join()}`)
      $(`#edit-broadcast ul.user-list ${liList.join()}`).click();
      $("#edit-broadcast input[name=broadcast-list-name]").val(result.roomName);
    }
  }

  var listUser = function(result) {
    let list = "";
    result.forEach( item => {
      list += `
        <li class="px-2 mb-3 each-user" user-id="${item.id}">
          <div class="d-flex">
            <div class="chat-user-img away align-self-center me-3 ms-0">
              <div class="avatar-xs">
                <span class="avatar-title rounded-circle custom-color-100" style="background-color: ${item.color}">
                  ${item.fullname[0].toLocaleUpperCase()}
                </span>
              </div>
            </div>
            <div class="flex-1 overflow-hidden">
              <h5 class="text-truncate font-size-15 mb-0 user-name">${htmlEncode(item.fullname) }</h5>
            </div>
          </div>
        </li>
      `
    })
    return list;
  }

  var handeClose = function() {
    $("#open-broadcast .back-btn").on("click", function() {
      $("#open-broadcast").hide();
      $(".layout-wrapper").show();
    });
  }
}).call(this);
