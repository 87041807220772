import consumer from "./consumer"
const messages_to_bottom = () => $("#user-chat-show .simplebar-content-wrapper").scrollTop($('#my-chat-history').prop("scrollHeight"))

let loadCount = 0;
$(document).on('turbolinks:load', function() {
  if (typeof current_user_token === 'undefined' || current_user_token === "") {
    return;
  }

  loadCount += 1;
  if (loadCount > 1) {
    return;
  }

  consumer.subscriptions.create({
    channel: "PollChannel",
    user_token: current_user_token,
  }, {
    connected() {
      console.log("Connected to the poll!");
      // Called when the subscription is ready for use on the server
    },

    disconnected() {
      // Called when the subscription has been terminated by the server
    },

    received(data) {
      let current_user_id = parseInt(sessionStorage.getItem('current_user_id'))
      let roomData = roomList.find(element => element.id === data.room_id)
      let conversations = roomData.conversations
      if (data.type == "vote") {
        let conversation = conversations.find(element => element.id === data.messageId)
        if (conversation) {
          conversation.poll.options = data.pollOptions
        }

        if (data.voteByUserId == current_user_id) {
          $(`#message-${data.messageId} .poll-button`).removeClass("voted");
        }

        data.pollOptions.forEach(function(option) {
          if (data.voteByUserId == current_user_id && option.votes.includes(current_user_id)) {
            $(`button[option-id='${option.id}']`).addClass("voted");
          }
          $(`button[option-id='${option.id}'] .vote-count`).text(option.votes.length > 0 ? option.votes.length : '');
        })
        return;
      }

      $("#conversation-no-" + data.room_id + " .chat-user-message").text("Poll card")

      const timeSent = moment.utc(data.content.created_at).tz(curentTimezone).format("HH:mm");
      const customName = data.room_type == 'zero_group' ? data.username : $(`.custom-fullname-${data.content.user_id}:first`).text();
      const isAtBottom = $("#my-chat-history").height() - ($("#user-chat-show .simplebar-content-wrapper").scrollTop() + $(".chat-conversation").height()) < 50

      $(".my-chat-init-message-room-" + data.room_id).html("")

      $(`#my-chat-history[room_id='${data.room_id}']`).append(`
        <li data-long-press-delay="500" class="${data.content.user_id == current_user_id ? 'right' : 'left'} poll-message conversation-messages" message-type="${ data.content.message_type }" id="message-${ data.content.id }">
          <div class="round">
            <input type="checkbox" disabled="disabled" class="message-check" id="checkbox-${ data.room_id }" />
            <label for="checkbox-${ data.room_id }"></label>
          </div>

          <div class="conversation-list">
            <div class="user-chat-content">
              <div class="ctext-wrap">
                <div class="ctext-wrap-content">
                  <h5 style="font-weight: 500">${ htmlEncode(data.poll.question) }</h5>
                  ${this.options(data.poll_options)}

                  <p>
                    Expires: <span class="simple-timer-${data.content.id}"></span>
                  </p>
                  <hr style="min-width:265px;" />
                  <div>
                    <span style="display: inline-flex;">
                      <i class="ri-pie-chart-2-line" style="margin-right: 5px;"></i> Poll
                    </span>
                  </div>
                </div>
              </div>
              ${ data.content.user_id != current_user_id ? `<div class="conversation-name custom-fullname-${data.content.user_id}">${ data.room_type == 'broadcast' ? "" : htmlEncode(customName || data.username) }</div>` : '' }
              <div class="chat-time mb-0">
                <span class="align-middle">${timeSent}</span>
                ${data.receive_ids.includes(current_user_id) || data.room_type == 'broadcast' ? '' : '<span class="align-middle send-status"><i class="ri-check-line"></i></span>'}
              </div>
            </div>
            ${ ["group_chat", "zero_group"].includes(data.room_type) ? '<p class="seen-user-list">...</p>' : ''}
          </div>
        </li>
      `);
      $(`.simple-timer-${data.content.id}`).syotimer({
        date: new Date(data.expiration_time),
      });

      let conversationsData = {
        message: null,
        id: data.content.id,
        userId: data.content.user_id,
        createdAt: data.content.created_at,
        name: data.username,
        deleteDate: data.content.delete_date,
        notSeenCount: 1,
        messageType: data.content.message_type,
        poll: {
          expirationTime: data.poll.expiration_time,
          question: data.poll.question,
          options: data.poll_options
        }
      };
      conversations.push(conversationsData);

      if (data.content.user_id == current_user_id) {
        messages_to_bottom();
      } else if (isAtBottom) {
        messages_to_bottom()
      }
      // Call function hold click for delete specific message
      handleLongPress($(`#message-${data.content.id}`));
      handleSelectMessage($(`#message-${data.content.id}`));
      $(".round").removeClass("open");

      // count up when received people not in conversation
      if ($('#my-chat-history').attr('room_id') != data.room_id && data.receive_ids.includes(current_user_id)) {
        console.log(data.read_status)
        var count = 0
        const readStatusData = data.read_status.find(s => s.user_id == current_user_id);
        if (readStatusData) {
          count = readStatusData.count
        }
        $("#conversation-no-" + data.room_id + " .unread-message span.rounded-pill").text(count)
        this.plus_statistic(data.room_type)
      }
    },
    options(options) {
      let data = "";
      options.forEach(function(option) {
        data += `<button option-id="${option.id}" class="btn poll-button">${ htmlEncode(option.title) }<span style="float: right;" class="vote-count"></span></button>`;
      })
      return data;
    },
    plus_statistic(room_type) {
      if (room_type == "dirrect") {
        if ($("#dirrect-message-counter.notify-counter").length == 0) {
          $("#dirrect-message-counter").addClass("notify-counter")
          $("#dirrect-message-counter").text(0)
        }
        $("#dirrect-message-counter").text(parseInt($("#dirrect-message-counter").text()) + 1);
      } else if (room_type == "group_chat") {
        if ($("#group-message-counter.notify-counter").length == 0) {
          $("#group-message-counter").addClass("notify-counter")
          $("#group-message-counter").text(0)
        }
        $("#group-message-counter").text(parseInt($("#group-message-counter").text()) + 1);
      } else if (room_type == "zero_group") {
        if ($("#zero-group-message-counter.notify-counter").length == 0) {
          $("#zero-group-message-counter").addClass("notify-counter")
          $("#zero-group-message-counter").text(0)
        }
        $("#zero-group-message-counter").text(parseInt($("#zero-group-message-counter").text()) + 1);
      }
    },
  })
});
