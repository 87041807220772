(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    friendRequestCounter();
  }

  var friendRequestCounter = function() {
    const counter = $("#pills-requests .contact-list li").length;
    if (counter > 0) {
      $(".friend-request-counter").addClass("notify-counter");
      $(".friend-request-counter").text(counter);
      $("#pills-requests-tab").show();
    }
  }
}).call(this);


