(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleCreateVaultNote();
    handleOpenNote();
    handleOpenNoteOnConv();
    handleCloseNoteOnConv();
    handleUpdateNote();
    handlePickNoteAndBook();
    hanldeSaveNote();
  }

  // handle save note when open on conversation
  var hanldeSaveNote = function() {
    $(".open-note-main #save-note").on("click", function() {
      const attachFileId = $("#open-note-file input[name=attach_file_id]").val();
      duplicateData(attachFileId);
    })
  }

  async function duplicateData(attachFileId) {
    $(".preload-encrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/vault_notes/duplicate",
      type: "POST",
      data: { attachFileId: attachFileId },
      async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      addNoteBlock(result);
      $(".preload-encrypt").hide();
      showToast("Save to vault completed!")
    }
  }

  // handle Note On Conversation
  var handleOpenNoteOnConv = function() {
    $("#my-chat-history").delegate(".each-file", "click", function() {
      const token = $(this).attr('token');
      getNote(token);
      $(".layout-wrapper").hide();
      $("#open-note-file").show();
    })
  }

  var handleCloseNoteOnConv = function() {
    $("#open-note-file .back-btn").on("click", function() {
      $("#open-note-file").hide();
      $(".layout-wrapper").show();
      $("#open-note-file .title").text("");
      $("#open-note-file .content").val("");
    })
  }

  async function getNote(token) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: `/api/v1/vault_notes/get_data?token=${token}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#open-note-file .title").text(result.title)
      $("#open-note-file .content").val(result.body)
      $("#open-note-file input[name=attach_file_id]").val(result.id)
      $(".preload-decrypt").hide();
    }
  }

  // update vault note
  var handleUpdateNote = function() {
    $("#update-vault-note-form").submit(function(e) {
      const dataString = $("form#update-vault-note-form").serialize();
      updateData(dataString);
      return false;
    });
  }

  async function updateData(dataString) {
    $(".preload-encrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/vault_notes/update_note",
      type: "PUT",
      data: dataString,
      async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#vault-listing-files .gallery-column.add-file-block").after($(`#vault-listing-files .vault-content-block[token='${result.fileName}']`).parent())
      $("#listing-files .gallery-column.add-file-block").after($(`#listing-files .vault-content-block[token='${result.fileName}']`).parent())

      $(".preload-encrypt").hide();
      $(`.vault-content-block[token='${result.fileName}'] .text-title`).text(result.title);
      $("#update-vault-note-modal").modal("hide");
    }
  }

  // create vault note
  var handleCreateVaultNote = function() {
    $("#create-vault-note-form").submit(function(e) {
      const dataString = $("form#create-vault-note-form").serialize();
      storeData(dataString);
      return false;
    });
  }

  async function storeData(dataString) {
    $(".preload-encrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/vault_notes",
      type: "POST",
      data: dataString,
      async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      addNoteBlock(result);
      $(".preload-encrypt").hide();
      resetPollForm();
    }
  }

  var resetPollForm = function() {
    $("#create-vault-note-modal #create-vault-note-form")[0].reset();
    $("#create-vault-note-modal").modal("hide");
  }

  var addNoteBlock = function(result) {
    const fileContent =
      `<div class='gallery-column'>
        <input type="checkbox" class='gallery-checkbox' id="gallery-checkbox-${result.id}" />
        <label for="gallery-checkbox-${result.id}" class='gallery-label'></label>
        <div class="each-block vault-content-block" token="${result.fileName}">
          <div class="text-icon"><i class="ri-file-text-line"></i></div>
          <h6 class="text-title">${ htmlEncode(result.title) }</h6>
          <div class="text-time-create">Created at: ${result.createdAt}</div>
          <div class="text-time-create">Updated at: ${result.updatedAt}</div>
        </div>
      </div>`;
    $("#attachment-chat .file-tab .add-file-block").after(fileContent);

    const vaultFileContent =
      `<div class='gallery-column'>
        <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
        <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
        <div class="each-block vault-content-block" token="${result.fileName}">
          <div class="text-icon"><i class="ri-file-text-line"></i></div>
          <h6 class="text-title">${ htmlEncode(result.title) }</h6>
          <div class="text-time-create">Created at: ${result.createdAt}</div>
          <div class="text-time-create">Updated at: ${result.updatedAt}</div>
        </div>
      </div>`;
    $("#pills-vaults .file-tab .add-file-block").after(vaultFileContent);
  }

  // open vault note
  var handleOpenNote = function() {
    $("#listing-files, #vault-listing-files").delegate(".vault-content-block", "click", function() {
      const token = $(this).attr('token');
      loadData(token)
    })
  }

  async function loadData(token) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: `/api/v1/vault_notes/get_data?token=${token}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#update-vault-note-form #note-token-input").val(result.fileName);
      $("#update-vault-note-form #note-title-input").val(result.title);
      $("#update-vault-note-form #note-body-input").val(result.body);
      $(".preload-decrypt").hide();
      $("#update-vault-note-modal").modal("show");
    }
  }

  // select note
  var handlePickNoteAndBook = function() {
    $("#listing-files, #listing-secured-book").delegate(".gallery-checkbox", "click", function() {
      chooseFile(this);
    })
  }

  var chooseFile = function(el) {
    var checked = $('#attachment-section .gallery-checkbox:checked').length;
    var id = el.id.split('gallery-checkbox-')[1];
    if ($(el).prop('checked')) {
      choose_attach_file_id.push(id);
    } else {
      choose_attach_file_id = choose_attach_file_id.filter(i => i != id);
    }

    if (checked > 0) {
      $('.attachment-chat-section').show();
      $('.attachment-tab').hide();
      $('.attachment-choose-amount').html(checked);
    } else {
      $('.attachment-chat-section').hide();
      $('.attachment-tab').show();
      $('.attachment-choose-amount').html('');
    }
    $("#attach-file-ids").val(choose_attach_file_id)
  }
}).call(this);
