(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    copyMessage();
  }

  var copyMessage = function() {
    $(".chat-message-copy-clipboard").on("click", function() {
      var copyList = []
      $("#my-chat-history .clicked").each(function( index ) {
        const name = $(this).find(".conversation-name").text() || $(".my-alias-name").text();
        const message = $(this).find(".message-content").text()?.replaceAll("&nbsp;", " ") || ""
        if (message) {
          copyList.push([name, message]);
        }
      });

      var copyText = "";
      var prevName = "";

      if (copyList.length === 1 && copyList[0][1]) {
        copyText = copyList[0][1]
      } else {
        copyList.forEach(function(el) {
          if(prevName === el[0]) {
            copyText += `${el[1]}\r\n`
          } else {
            copyText += `\r\n${el[0]}:\r\n${el[1]}\r\n`
          }
          prevName = el[0];
        })
      }

      holdClick();

      var $temp = $("<textarea>");
      $("body").append($temp);
      $temp.val(copyText).select();
      document.execCommand("copy");
      $temp.remove();

      if (copyText) {
        $(".toast-body").text("Text copied to clipboard");
      } else {
        $(".toast-body").text("Can't copy to clipboard");
      }

      $(".toast-block").show();
      $('.toast').toast({ delay: 2000})
      $('.toast').toast("show")
      $(".toast-block").animate({width:'show'}, 2000);
    })
  }
}).call(this);


