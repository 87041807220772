(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleRemoveMemberFromGroup();
    handlePromoteToMOD();
    handleRemoveAdMOD();
  }

  var handleRemoveMemberFromGroup = function() {
    $("#my-group-action").delegate(".remove-from-group", "click", function() {
      $(this).parent().parent().parent().parent().remove()
    });
  }

  var handlePromoteToMOD = function() {
    $("#my-group-action").delegate(".promote-to-mod", "click", function() {
      const li = $(this).parent().parent().parent().parent();
      const userId = li.attr("user_id");
      li.prepend(
        `<input type="hidden" name="mods[]" class="form-check-input" value="${userId}">`
      )
      li.find(".role-block").text("MOD");

      $(this).addClass("remove-as-mod");
      $(this).html(`Remove as MOD <i class="ri-indeterminate-circle-line float-end text-muted"></i>`)
      $(this).removeClass("promote-to-mod");
    });
  }

  var handleRemoveAdMOD = function() {
    $("#my-group-action").delegate(".remove-as-mod", "click", function() {
      const li = $(this).parent().parent().parent().parent();
      const userId = li.attr("user_id");
      li.find("input[name='mods[]']").remove();
      li.find(".role-block").text("");

      $(this).addClass("promote-to-mod");
      $(this).html(`Promote to MOD <i class="ri-admin-line float-end text-muted"></i>`)
      $(this).removeClass("remove-as-mod");
    });
  }
}).call(this);