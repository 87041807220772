(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    const currentUserId = parseInt(sessionStorage.getItem('current_user_id'));
    $("#user-chat-show").delegate("#start-screenshot-conversation", "click", function(e) {
      $(".preload-export").show();
      $("#pdf-layout .user-chat").addClass("user-chat-show");
      const roomId = $(this).attr("room_id");
      const roomName = $(this).attr("room_name");

      screenshot(roomId, roomName, currentUserId);
    });

    handleOpenConversation();
    handleCloseConversation();
  }

  async function screenshot(roomId, roomName, currentUserId) {
    let roomData = {};
    let isLoading = true;
    await $.ajax({
      url: `/api/v1/conversations/listing?room_id=${roomId}&getAll=true`,
      type: "GET",
      async: true,
      success: function(data) {
        roomData =  data.data;
        let pollArray = []
        const ulContent = listingConversation(roomData, currentUserId, pollArray);

        $("#pdf-layout").show()
        $("#pdf-layout #my-chat-history").html(ulContent);
        pollArray.forEach(function(message) {
          $(`.simple-timer-${message.id}`).syotimer({
            date: new Date(message.expirationTime),
          });
        })
        isLoading = false;
      }
    });

    if (isLoading === false) {
      exportCanvas(roomName);
    }
  }

  var exportCanvas = function(roomName) {
    Promise.all(
      [
        new Promise(function (resolve)
        {
          html2canvas($('#pdf-layout'),
          {
            onrendered: function (canvas)
            {
              resolve(canvas.toDataURL('image/jpeg', 0.8));
            }
          });
        }),
      ]
    ).then(function (screens) {
      // console.log(screens);
      uploadFile(screens[0], roomName)
    })

    // html2canvas($("#pdf-layout"), {
    //   onrendered: function(canvas) {
    //     var imgsrc = canvas.toDataURL("image/jpeg");
    //     // console.log(imgsrc);
    //     uploadFile(imgsrc, roomName)
    //   }
    // });
  }

  async function uploadFile(imgsrc, roomName) {
    let isLoading = true;
    await $.ajax({
      type: "POST",
      url: '/api/v1/exports/upload_file',
      data: {
        base64Data : imgsrc,
        roomName: roomName
      },
      async: true,
      success: function(data) {
        const result = data.data
        const vaultFileContent =
          `<div class='gallery-column'>
            <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
            <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
            <div class="each-block vault-content-block" token="${result.fileName}">
              <div class="text-icon"><i class="ri-file-text-line"></i></div>
              <h6 class="text-title">${ htmlEncode(result.title) }</h6>
              <div class="text-time-create">${result.createdAt}</div>
            </div>
          </div>`;
        $("#pills-vaults .conversation-tab #vault-listing-conversations").prepend(vaultFileContent);
        showToast("Export chat logs to vault completed!")
        isLoading = false;
      }
    });
    if (isLoading === false) {
      $("#pdf-layout #my-chat-init-message").html("");
      $("#pdf-layout #my-chat-history").empty();
      $("#pdf-layout").hide();
      $(".preload-export").hide();
    }
  }

  var handleCloseConversation = function() {
    $("#open-conversation-file .back-btn").on("click", function() {
      $("#open-conversation-file").hide();
      $(".layout-wrapper").show();
      $("#open-conversation-file .title").text("");
      $("#open-conversation-file .content").val("");
    })
  }

  // open vault note
  var handleOpenConversation = function() {
    $("#vault-listing-conversations").delegate(".vault-content-block", "click", function() {
      const token = $(this).attr('token');
      loadData(token)
      $(".layout-wrapper").hide();
      $("#open-conversation-file").show();
    })
  }

  async function loadData(token) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: `/api/v1/exports/detail?token=${token}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#open-conversation-file .title").text(result.title)
      $("#open-conversation-file .view-conversation").attr("src", result.rawImage)
      $(".preload-decrypt").hide();
    }
  }
}).call(this);
