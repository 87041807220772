// manage on pills-vaults (home page)
(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    openEditSecuredBook();
    handleCloseSecuredBook();
    handleEnableSaveBtn();
    handleSave();
    handleViewBook();
    handleOpenSecuredBookOnConv();
    handleCloseSecuredBookOnConv();
    hanldeDownloadSecuredBook();
    // handlePickNoteAndBook => check on vault_note.js
  }

   var hanldeDownloadSecuredBook = function() {
    $("#download-secured-book").on("click", function() {
      const attachFileId = $("#open-secured-book input[name=attach_file_id]").val();
      duplicateData(attachFileId);
    })
  }

  async function duplicateData(attachFileId) {
    $(".preload-encrypt").show();
    let loading = true
    var result;

    await $.ajax({
      url: "/api/v1/secured_books/duplicate",
      type: "POST",
      data: { attachFileId: attachFileId },
      async: true,
      success: function(data) {
        result = data.data;
        loading = false
      }
    });

    if (!loading) {
      addBookBlock(result);
      $(".preload-encrypt").hide();
      showToast("Save to vault completed!")
    }
  }

  var handleCloseSecuredBookOnConv = function() {
    $("#open-secured-book .back-btn").on("click", function() {
      $("#open-secured-book").hide();
      $(".layout-wrapper").show();
      $("#open-secured-book .title").text("");
      $("#table-headers").empty();
      $("#table-body").empty();
    })
  }

  // handle Book On Conversation
  var handleOpenSecuredBookOnConv = function() {
    $("#my-chat-history").delegate(".each-secured-book", "click", function() {
      const token = $(this).attr('token');
      getBookOnConv(token);
      $(".layout-wrapper").hide();
      $("#open-secured-book").show();
    })
  }

  var handleViewBook = function() {
    $("#listing-secured-book, #vault-listing-secured-book").delegate(".vault-content-block", "click", function() {
      $("#edit-secured-book .continue-btn").addClass("active");
      $("#edit-secured-book .continue-btn").prop("disabled", false);
      const token = $(this).attr('token');
      loadData(token)
    })
  }

  async function getBookOnConv(token) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: `/api/v1/secured_books/get_data?token=${token}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#open-secured-book .title").text(result.title)
      localStorage.setItem("spreadsheet_db", result.body);
      $("#open-secured-book input[name=attach_file_id]").val(result.id)
      $(".preload-decrypt").hide();
      createSpreadsheet("view");
      $("#open-secured-book #table-body td").prop("contenteditable", false)
      $(".dropbtn").hide();
    }
  }

  async function loadData(token) {
    $(".preload-decrypt").show();
    let result = "";
    let loading = true

    await $.ajax({
      url: `/api/v1/secured_books/get_data?token=${token}`,
      type: "GET",
      async: true,
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#edit-secured-book input.title").val(result.title)
      $("#edit-secured-book input.file-name").val(result.fileName)
      localStorage.setItem("spreadsheet_db", result.body);

      $(".preload-decrypt").hide();
      $(".layout-wrapper").hide();
      $("#edit-secured-book").show();
      createSpreadsheet();
    }
  }

  var handleSave = function() {
    $("#edit-secured-book .continue-btn").on("click", function() {
      const title = $("#edit-secured-book input.title").val();
      const csv_content = localStorage.getItem("spreadsheet_db");
      const fileName = $("#edit-secured-book input.file-name").val();
      uploadData(title, csv_content, fileName)
    })
  }

  async function uploadData(title, csv_content, file_name) {
    let loading = true;
    var result;
    $(".preload-encrypt").show();

    await $.ajax({
      url: "/api/v1/secured_books/upload",
      type: "POST",
      async: true,
      data: { title: title, csv_content: csv_content, file_name: file_name },
      success: function(data) {
        result =  data.data;
        loading = false
      }
    });

    if (!loading) {
      $("#vault-listing-secured-book .gallery-column.add-file-block").after($(`#vault-listing-secured-book .vault-content-block[token='${result.fileName}']`).parent())
      $("#listing-secured-book .gallery-column.add-file-block").after($(`#listing-secured-book .vault-content-block[token='${result.fileName}']`).parent())

      $(".preload-encrypt").hide();
      $("#edit-secured-book").hide();
      $(".layout-wrapper").show();
    }
    if (file_name.length > 0) {
      $(`.vault-content-block[token='${result.fileName}'] .text-title`).text(result.title);
    } else {
      addBookBlock(result);
    }
  }

  var addBookBlock = function(result) {
    const securedBookContent =
      `<div class='gallery-column'>
        <input type="checkbox" class='gallery-checkbox' id="gallery-checkbox-${result.id}" />
        <label for="gallery-checkbox-${result.id}" class='gallery-label'></label>
        <div class="each-block vault-content-block" token="${result.fileName}">
          <div class="text-icon"><i class="ri-file-text-line"></i></div>
          <h6 class="text-title">${ htmlEncode(result.title) }</h6>
          <div class="text-time-create">Created at: ${result.createdAt}</div>
          <div class="text-time-create">Updated at: ${result.updatedAt}</div>
        </div>
      </div>`;
    $("#attachment-chat .secured-book-tab .add-file-block").after(securedBookContent);

    const vaultSecuredBookContent =
      `<div class='gallery-column'>
        <input type="checkbox" class='gallery-checkbox' id="vault-checkbox-${result.id}" />
        <label for="vault-checkbox-${result.id}" class='gallery-label'></label>
        <div class="each-block vault-content-block" token="${result.fileName}">
          <div class="text-icon"><i class="ri-file-text-line"></i></div>
          <h6 class="text-title">${ htmlEncode(result.title) }</h6>
          <div class="text-time-create">Created at: ${result.createdAt}</div>
          <div class="text-time-create">Updated at: ${result.updatedAt}</div>
        </div>
      </div>`;
    $("#pills-vaults .secured-book-tab .add-file-block").after(vaultSecuredBookContent);
  }

  var handleEnableSaveBtn = function() {
    $("#edit-secured-book input.title").on("change paste keyup", function(e) {
      if ($("#edit-secured-book input.title").val().length > 0) {
        $("#edit-secured-book .continue-btn").addClass("active");
        $("#edit-secured-book .continue-btn").prop("disabled", false);
      } else {
        $("#edit-secured-book .continue-btn").removeClass("active");
        $("#edit-secured-book .continue-btn").prop("disabled", true);
      }
    })
  }

  var openEditSecuredBook = function() {
    $(".secured-book-tab .add-more-block").on("click", function() {
      $(".layout-wrapper").hide();
      $("#edit-secured-book").show();
      $("#edit-secured-book input.title").val("")
      $("#edit-secured-book input.file-name").val("")
      $("#edit-secured-book .continue-btn").prop("disabled", true);
      $("#edit-secured-book .continue-btn").removeClass("active");
      resetDataSpreadsheet();
    })
  }

  var handleCloseSecuredBook = function() {
    $("#edit-secured-book .back-btn").on("click", function() {
      $("#edit-secured-book").hide();
      $(".layout-wrapper").show();
      // $("#open-note-file .title").text("");
      // $("#open-note-file .content").val("");
    })
  }
}).call(this);
