(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleSetPin();
    handleSetUnPin();
  }

  resetHandlePin = function() {
    handleSetPin();
    handleSetUnPin();
  }

  var handleSetUnPin = function() {
    $(".set-unpin-btn").on("click", function() {
      const roomIds = $(".room-list li.clicked").map(function() { return $(this).attr("id").substring(16); }).get()
      storeUnPinData(roomIds)

      const classType = $(this).attr("data")
      roomIds.forEach((roomId) => {
        if ($(`.${classType} .pin-icon`).length !== 1) {
          $("#conversation-no-" + roomId).insertAfter($(`.${classType} .pin-icon:last`).parent().parent().parent().parent());
        }
      });


      $(".room-list li.clicked .pin-icon").after(`<span class="is-unpin"></span>`);
      $(".room-list li.clicked .pin-icon").remove();

      $(".cancel-select-group").click();
    })
  }

  var handleSetPin = function() {
    $(".set-pin-btn").on("click", function() {
      const roomIds = $(".room-list li.clicked").map(function() { return $(this).attr("id").substring(16); }).get()
      storeData(roomIds);

      const classType = $(this).attr("data")
      roomIds.forEach((roomId) => {
        if ($(`.${classType} .pin-icon:last`).length) {
          $("#conversation-no-" + roomId).insertAfter($(`.${classType} .pin-icon:last`).parent().parent().parent().parent());
        } else {
          $("#conversation-no-" + roomId).prependTo(`.${classType}`);
        }
      });

      $(".room-list li.clicked .is-unpin").before(`<i class="ri-pushpin-fill pin-icon"></i>`);
      $(".room-list li.clicked .is-unpin").remove();

      $(".cancel-select-group").click();
    })
  }

  function storeData(roomIds) {
    $.ajax({
      url: "/api/v1/rooms/set_pin",
      type: "POST",
      data: { roomIds: roomIds },
      async: true
    });
  }

  function storeUnPinData(roomIds) {
    $.ajax({
      url: "/api/v1/rooms/set_unpin",
      type: "POST",
      data: { roomIds: roomIds },
      async: true
    });
  }
}).call(this);
