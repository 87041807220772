(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleSearch();
  }

  var handleSeachForward = function() {
    $(".input-search-forward").on('keyup input', function () {
      var filter = $(this).val();
      $("ul.forward-group-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
      });
    });
  }

  var handleSearch = function() {
    $(".side-menu .input-search-dirrect-chat").on("keyup input", function () {
      var filter = $(this).val();

      if ($("#pills-contacts").hasClass("active")) {
        $("ul.contact-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
        });
      }

      if ($("#pills-chat").hasClass("active")) {
        $("ul.chat-user-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
        });
      }

      if ($("#pills-groups").hasClass("active")) {
        $("ul.chat-group-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
        });
      }

      if ($("#pills-zero-groups").hasClass("active")) {
        $("ul.chat-zero-group-list li").each(function () {
          if ($(this).text().search(new RegExp(filter, "i")) < 0) {
              $(this).hide();
          } else {
              $(this).show()
          }
        });
      }

    });
  }
}).call(this);
