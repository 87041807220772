(function() {
  $(document).on('turbolinks:load', function() {
    init();
  });

  var init = function() {
    handleChatTab()
    handleGroupTab()
    handleZeroGroupTab()
    handleContactTab()
    handleVaultTab()
    handleRequestTab()
    handleRootButton()
    handleSettingButton()
    handleSearchButton()
  }

  var handleSettingButton = function() {
    $(".side-menu .go-settings-btn").on("click", function() {
      $("#settings-block").addClass("active")
    })

    $("#settings-block .back-btn").on("click", function() {
      $("#settings-block").removeClass("active")
    })
  }

  var handleSearchButton = function() {
    $(".side-menu .search-btn").on("click", function() {
      $(".side-menu .search-box").show()
      $(".side-menu .user-chat-nav").hide();
      $(".side-menu .top-select-group-display").hide();
    })

    $(".side-menu .back-btn").on("click", function() {
      resetSearchBox()
      $(".side-menu .search-box").hide()
      $(".side-menu .user-chat-nav").show();
      $(".side-menu .top-select-group-display").show();
    })
  }

  var handleRootButton = function() {
    $("#root-add-button").on("click", function() {
      $("#root-add-block").addClass("active")
    })

    $("#root-add-block .back-btn").on("click", function() {
      $("#root-add-block").removeClass("active")
    })

    $("#root-add-block .link-action").on("click", function() {
      $("#root-add-block").removeClass("active")
    })

    $("#root-add-block ul.root-user-list").delegate("li", "click", function() {
      $("#root-add-block").removeClass("active")
    })
  }

  var handleChatTab = function() {
    $('#pills-chat-tab').on('click', function() {
      $('.side-menu .tab-name').text('Chats')
      $(".side-menu .list-inline-item .dropdown").hide()
      $('.side-menu .chat-tools').show()
      $('.side-menu .set-pin-btn').attr('data', 'chat-user-list')
      $('.side-menu .set-unpin-btn').attr('data', 'chat-user-list')
    })
  }
  var handleGroupTab = function() {
    $('#pills-groups-tab').on('click', function() {
      $('.side-menu .tab-name').text('Groups')
      $(".side-menu .list-inline-item .dropdown").hide()
      $('.side-menu .group-tools').show()
      $('.side-menu .set-pin-btn').attr('data', 'chat-group-list')
      $('.side-menu .set-unpin-btn').attr('data', 'chat-group-list')
    })
  }
  var handleZeroGroupTab = function() {
    $('#pills-zero-groups-tab').on('click', function() {
      $('.side-menu .tab-name').text('Zero Knowledge Groups')
      $(".side-menu .list-inline-item .dropdown").hide()
      $('.side-menu .zero-tools').show()
      $('.side-menu .set-pin-btn').attr('data', 'chat-zero-group-list')
      $('.side-menu .set-unpin-btn').attr('data', 'chat-zero-group-list')
    })
  }
  var handleContactTab = function() {
    $('#pills-contacts-tab').on('click', function() {
      $('.side-menu .tab-name').text('Contacts')
      $(".side-menu .list-inline-item .dropdown").hide()
      $('.side-menu .contact-tools').show()
    })
  }
  var handleVaultTab = function() {
    $('#pills-vaults-tab').on('click', function() {
      $('.side-menu .tab-name').text('Vault')
      $(".side-menu .list-inline-item .dropdown").hide()
      $('.side-menu .vault-tools').show()
    })
  }

  var handleRequestTab = function() {
    $('#pills-requests-tab').on('click', function() {
      $('.side-menu .tab-name').text('Requests')
      $(".side-menu .list-inline-item .dropdown").hide()
      $('.side-menu .request-tools').show()
    })
  }
}).call(this);
